import { Button, HStack, Img, Input, Link, Text, VStack, useToast } from "@chakra-ui/react"
import { AuthTemplate } from "../../../templates/AuthTemplate"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { RequestRecover } from "../../../services/AccountService"
import { useScreenSize } from "../../../providers/ScreenSizeProvider"

export const ForgotPasswordPage = () => {
    const [is_sending, setIsSending] = useState<boolean>()
    const [email, setEmail] = useState<string>('')
    const navigate = useNavigate()
    const toast = useToast()
    const { isMobile } = useScreenSize()

    const isValidEmail = () => {
        return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const send = async ()=> {
        setIsSending(()=> true)
        try{
            const { token } = await RequestRecover(email)
            navigate(`/recover/verify/${token}`)
        }catch(e: any){
            toast({
                status: `error`,
                description: e.message,
                duration: 3000,
                position: 'bottom-right'
            })
        }
        setIsSending(()=> false)
    }

    return (
        <AuthTemplate>
            <VStack spacing={'32px'} 
                    align={'start'} 
                    px={'24px'} 
                    py={!isMobile ? '20px' : '60px'}
                    w={!isMobile ? '500px' : 'full'}
                    h={!isMobile ? '600px' : 'full'}
                    bg={'#262333'} 
                    border={'1px solid #2F2C3B'} 
                    borderRadius={'8px'}>
                <VStack spacing={'20px'} w={'full'} align={'start'}>
                    <Img h={'50px'} cursor={'pointer'} onClick={()=> navigate(`/`)} src={'/assets/images/logo-vertical.png'} />
                    <VStack align={'start'} w={'full'} spacing={0}>
                        <Text fontWeight={600} fontSize={'2xl'}>Forgot your password?</Text>
                        <Text fontWeight={400} color={'gray.400'}>No worries. Please enter your email to reset your password.</Text>
                    </VStack>
                </VStack>
                <VStack w={'full'} spacing={'16px'}>
                    <Input value={email} onChange={e => setEmail(e.target.value)} variant={'filled'} fontSize={'sm'} size={'lg'} placeholder={'Email Address'} />
                    <Button isLoading={is_sending} loadingText={`Sending request...`} isDisabled={!isValidEmail()} mt={'8px'} onClick={send} _hover={{ bg: 'green.300' }} w={'full'} bg={'green.400'} color={'black'}>
                        Proceed
                    </Button>
                    <HStack align={'center'} justify={'center'} w={'full'}>
                        <Text color={'gray.400'} fontSize={'sm'}>Remember your password?</Text>
                        <Link onClick={()=> navigate(`/login`)} color={'gray.300'} fontWeight={600}>Login</Link>
                    </HStack>
                </VStack>
            </VStack>
        </AuthTemplate>
    )
}