import { Button, Center, Divider, HStack, IconButton, Image, Img, Slide, StackProps, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { BiMenu } from "react-icons/bi"
import { FiChevronDown, FiMenu, FiX } from "react-icons/fi"
import { useNavigate } from "react-router-dom"
import { Link } from "./Link"
import { useContext } from "react"
import { UserContext } from "../providers/UserProvider"
import { RiUserLine } from "react-icons/ri"
import { SelectUploadType } from "../modules/contents/components/SelectUploadType"

interface MobileHeaderProps {
    activeNav: string;
}

const container_style: StackProps = {
    w: 'full',
    py: '24px',
    px: '80px',
    justify: 'space-between',
    pos: 'relative',
    zIndex: 1000,
    transition: 'background-color 200ms linear'
}

const floating_container_style: StackProps = {
    ...container_style,
    pos: 'fixed',
    zIndex: 200,
    bg: 'transparent',
    transition: 'background-color 200ms linear'
}

export const MobileHeader = ({ activeNav: active_nav }: MobileHeaderProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()
    const { user } = useContext(UserContext)
    return (
        <>
            <HStack pos={'fixed'} zIndex={20} bg={'#262333'} w={'full'} justify={'space-between'} p={'16px'}>
                <Img cursor={'pointer'} onClick={() => navigate('/')} h={'20px'} src="/assets/images/logo.png" />
                <IconButton onClick={onOpen} size={'lg'} variant={'ghost'} aria-label={'Menu'} icon={<FiMenu />} colorScheme={'gray'} />
            </HStack>
            <Slide direction={'right'} in={isOpen} style={{ zIndex: 100 }}>
                <VStack p={'24px'} spacing={'24px'} align={'start'} bg={'#1F1C2C'} pos={'fixed'} h={window.innerHeight} w={'80%'} right={0}>
                    <HStack w={'full'} justify={'end'}>
                        <IconButton onClick={onClose} size={'lg'} variant={'ghost'} aria-label={'close'} icon={<FiX />} />
                    </HStack>
                    <Link onClick={() => navigate('/')} isActive={active_nav === 'HOME'}>Home</Link>
                    <Link onClick={() => navigate('/explore')} isActive={active_nav === 'EXPLORE'}>Explore</Link>
                    <Link onClick={() => navigate('/genre')} isActive={active_nav === 'GENRE'}>Genre</Link>
                    <Link isActive={active_nav === 'MERCHANDISE'}>Merchandise</Link>
                    <Link isActive={active_nav === 'ABOUT_US'}>About Us</Link>
                    <Link isActive={active_nav === 'HELP_CENTER'}>Help Center</Link>
                    <Divider />
                    {!user &&
                        <VStack w={'full'} spacing={'16px'}>
                            <Button w={'full'} onClick={() => navigate('/login')} px={'24px'} colorScheme={'gray'} color={'green.400'}>Log In</Button>
                            <Button w={'full'} onClick={() => navigate('/register')} px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'}>Sign Up</Button>
                        </VStack>
                    }
                    {user &&
                        <>
                            <HStack py={'6px'} spacing={'12px'}>
                                {user.profile_picture_url ?
                                    <Image
                                        borderRadius={'4px'}
                                        boxSize='43px'
                                        objectFit='cover'
                                        src={user.profile_picture_url}
                                        alt={'Profile Picture'}
                                    />
                                    :
                                    <Center borderRadius={'8px'} borderWidth={'2px'} borderColor={'white'} h={'40px'} w={'42px'}>
                                        <RiUserLine size={'20px'} color={'white'} />
                                    </Center>
                                }

                                <VStack align={'start'} spacing={0}>
                                    <Text fontSize={'14px'}>{user.firstname} {user.lastname}</Text>
                                    <Text fontSize={'small'} color={'gray.400'}>{user.email}</Text>
                                </VStack>
                            </HStack>
                            <SelectUploadType w={'full'}>
                                <Button w={'full'} px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'}>
                                    Upload Content
                                </Button>
                            </SelectUploadType>
                            <Link onClick={() => navigate('/account/settings')} isActive={active_nav === 'ACCOUNT_SETTINGS'}>Account Settings</Link>
                            <Link onClick={()=> navigate(`/account/contents`)} isActive={active_nav === 'MY_UPLOADS'}>My Uploads</Link>
                            <Link isActive={active_nav === 'MY_LIBRARY'}>My Library</Link>
                            <Link isActive={active_nav === 'MY_WALLET'}>My Wallet</Link>
                            <Link isActive={active_nav === 'CREATOR_DASHBOARD'}>Creator Dashboard</Link>
                            <Link isActive={active_nav === 'HELP_CENTER'}>Help Center</Link>
                            <Link isActive={active_nav === 'MERCHANDISE'}>Logout</Link>
                        </>
                    }
                </VStack>
            </Slide>
        </>

    )
}