import { Button, Center, Img, Input, Text, VStack, useToast } from "@chakra-ui/react"
import { AuthTemplate } from "../../../templates/AuthTemplate"
import { useEffect, useState } from "react"
import { ChangePassword } from "../../../services/AccountService"
import { useNavigate } from "react-router-dom"

export const ChangePasswordPage = () => {
    const [is_saving, setIsSaving] = useState<boolean>()
    const [password, setPassword] = useState<string>('')
    const [confirm_password, setConfirmPassword] = useState<string>('')
    const [error, setError] = useState<string>('')
    const toast = useToast()
    const navigate = useNavigate()

    useEffect(()=> {
        if(confirm_password && confirm_password!==password){
            setError(()=> `The passwords you entered didn't match`)
        }
    }, [confirm_password])

    const isValid = () => {
        return password && password.length > 8 && password === confirm_password
    }

    const save = async () => {
        setIsSaving(() => true)
        try {
            await ChangePassword(password)
            navigate(`/account/recover/success`)
        } catch (e: any) {
            console.log(e, 'error')
            toast({
                status: `error`,
                description: e.message,
                duration: 3000,
                position: 'bottom-right'
            })
        }
        setIsSaving(() => false)
    }

    return (
        <AuthTemplate>
            <VStack spacing={'32px'} align={'start'} py={'20px'} px={'24px'} w={'500px'} minH={'500px'} bg={'#1F1C2C'} border={'1px solid #2F2C3B'} borderRadius={'8px'}>
                <VStack spacing={'20px'} w={'full'} align={'start'}>
                    <Img h={'50px'} src={'/assets/images/logo-vertical.png'} />
                    <VStack align={'start'} w={'full'} spacing={0}>
                        <Text fontWeight={600} fontSize={'2xl'}>Reset Password</Text>
                        <Text fontWeight={400} color={'gray.400'}>Enter your new password</Text>
                    </VStack>
                </VStack>
                <VStack w={'full'} spacing={'16px'}>
                    <Input value={password} onChange={e => setPassword(e.target.value)} variant={'filled'} fontSize={'sm'} size={'lg'} placeholder={'Enter New Password'} type={'password'} />
                    <Input value={confirm_password} onChange={e => setConfirmPassword(e.target.value)} variant={'filled'} fontSize={'sm'} size={'lg'} placeholder={'Confirm Password'} type={'password'} />
                    {error &&
                        <Center w={'full'} borderRadius={'8px'} p={'16px'} bg={'#262333'}>
                            <Text fontSize={'16px'} color={'orange.400'}>
                                {error}
                            </Text>
                        </Center>
                    }
                    <Button isLoading={is_saving} loadingText={`Saving...`} isDisabled={!isValid()} mt={'8px'} onClick={save} _hover={{ bg: 'green.300' }} w={'full'} bg={'green.400'} color={'black'}>
                        Proceed
                    </Button>
                </VStack>
            </VStack>
        </AuthTemplate>
    )
}