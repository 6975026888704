import { Box, BoxProps, Grid, GridItem, HStack, Img, Stack, StackProps, Text, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { GetGenreListing } from "../../../services/ContentService"
import { GenreCard } from "./GenreCard"
import { useNavigate } from "react-router-dom"
import { useScreenSize } from "../../../providers/ScreenSizeProvider"

const container_style: StackProps = {
    w: 'full',
    py: '54px',
    align: 'center',
    bg: 'black',
}

const view_all_style: StackProps = {
    overflow: 'hidden',
    borderRadius: '16px',
    pos: 'relative',
    bg: 'transparent',
    w: '320px',
    h: '320px',
    align: 'center',
    justify: 'center'
}

const backdrop_style: BoxProps = {
    bg: 'white',
    opacity: 0.1,
    pos: 'absolute',
    w: 'full',
    h: 'full',
    zIndex: 10
}



export const GenreCardGrid = () => {
    const [genres, setGenres] = useState<any[]>([])
    const navigate = useNavigate()
    const { isMobile } = useScreenSize()

    useEffect(() => {
        (async () => {
            const result = await GetGenreListing()
            let slice_count = 8
            if (isMobile) {
                slice_count = 4
            }
            setGenres(() => [...result.slice(0, slice_count)])
        })()
    }, [])

    return (
        <VStack {...container_style}>
            <VStack spacing={'32px'} align={'start'}>
                <Text fontWeight={600} fontSize={'2xl'}>Genres</Text>
                <Stack direction={!isMobile ? 'row' : 'column'} spacing={'20px'}>
                    <Grid
                        templateRows='repeat(2, 1fr)'
                        templateColumns={`repeat(${!isMobile ? 4 : 2}, 1fr)`}
                        gap={'20px'}
                    >
                        {genres.map((item: any, index: number) =>
                            <GenreCard onClick={() => navigate(`/genre/${item.code.toLowerCase()}`)} key={index} genre={item} />
                        )}
                    </Grid>

                    <VStack cursor={'pointer'} {...view_all_style} w={!isMobile ? '320px' : 'full'} onClick={() => navigate(`/genre`)}>
                        <Box {...backdrop_style}></Box>
                        <Text fontWeight={600} fontSize={'72px'}>+3</Text>
                        <Text fontWeight={600} fontSize={'2xl'}>View All</Text>
                    </VStack>
                </Stack>
            </VStack>
        </VStack>

    )
}