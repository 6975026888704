import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"


export const ContentPurchaseSuccessModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Modal Title</ModalHeader>
                <ModalCloseButton onClick={onClose} />
                <ModalBody></ModalBody>
            </ModalContent>
        </Modal>
    )
}