import { HStack, Text, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ContentSliderProps {
    title: string;
    subTitle?: string;
    titleButtons?: ReactNode;
    children?: ReactNode;
}

export const ContentSlider = ({ title, subTitle: sub_title, titleButtons: title_buttons, children }: ContentSliderProps) => {
    return (
        <VStack w={'full'} maxW={'1460px'} spacing={'32px'}>
            <HStack w={'full'} justify={'space-between'} align={'center'}>
                <VStack align={'start'} spacing={'4px'}>
                    <Text fontSize={'3xl'}>{title}</Text>
                    <Text fontWeight={400}>{sub_title}</Text>
                </VStack>
                <HStack>
                    {title_buttons}
                </HStack>
            </HStack>
            {children}
        </VStack>
    )
}