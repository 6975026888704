import { Button, HStack, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea, useDisclosure, VStack } from "@chakra-ui/react"
import { useContext, useState } from "react"
import { Link } from "../../../components/Link"
import { UserContext } from "../../../providers/UserProvider"
import { useScreenSize } from "../../../providers/ScreenSizeProvider";

interface SaveBasicInfoModalProps {
    onSave?: (basic_info: any) => void;
}

export const SaveBasicInfoModal = ({ onSave }: SaveBasicInfoModalProps) => {
    const { user } = useContext(UserContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [firstname, setFirstname] = useState<string>(user.firstname)
    const [lastname, setLastname] = useState<string>(user.lastname)
    const [bio, setBio] = useState<string>(user.bio)
    const { isMobile } = useScreenSize()

    const save = async () => {
        if (onSave) {
            onSave({ firstname, lastname, bio })
        }
        onClose()
    }

    return (
        <>
            <Link onClick={onOpen}>Edit</Link>

            <Modal size={isMobile ? 'sm' : 'xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={'#1F1C2C'}>
                    <ModalHeader>Basic Information</ModalHeader>
                    <ModalBody>
                        <VStack spacing={'32px'} w={'full'}>
                            <HStack w={'full'} align={'start'}>
                                <VStack spacing={'4px'} w={'full'} align={'start'}>
                                    <Text>First name</Text>
                                    <Input value={firstname} onChange={e => setFirstname(e.target.value)} variant={'filled'} placeholder={'Enter first name'} />
                                </VStack>
                                <VStack spacing={'4px'} w={'full'} align={'start'}>
                                    <Text>Last name</Text>
                                    <Input value={lastname} onChange={e => setLastname(e.target.value)} variant={'filled'} placeholder={'Enter last name'} />
                                </VStack>
                            </HStack>
                            <VStack spacing={'4px'} w={'full'} align={'start'}>
                                <Text>Bio</Text>
                                <Textarea value={bio} onChange={e => setBio(e.target.value)} placeholder={'Enter bio'} variant={'filled'} resize={'none'} rows={5} />
                            </VStack>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack w={'full'} spacing={'20px'}>
                            <Button w={'50%'} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button w={'50%'} bg={'green.400'} colorScheme='green' onClick={save}>
                                Save
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}