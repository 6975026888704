import { Box, Button, Center, Checkbox, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, StackProps, Text, Textarea, VStack, useDisclosure } from "@chakra-ui/react"
import { ReactNode, useEffect, useState } from "react"
import { FiDollarSign, FiGift } from "react-icons/fi"
import { ContentFileProps, ContentProps, SubContentProps } from "../../../services/ContentService"
import { UploadFileBox } from "./UploadFileBox"
import { AssetType } from "../../../services/AssetService"
import { useScreenSize } from "../../../providers/ScreenSizeProvider"

interface SelectUploadTypeProps {
    subContent: SubContentProps;
    mainContent: ContentProps;
    onSetSubContent?: (subContent: SubContentProps) => void;
    children: ReactNode;
}

const pricing_style: StackProps = {
    spacing: '4px',
    borderRadius: '8px',
    bg: '#2F2C3B',
    w: '50%',
    h: '95px',
    justify: 'center',
    cursor: 'pointer'
}

const active_pricing_style = {
    ...pricing_style,
    bg: '#353444',
    borderColor: '#98A2B3',
    borderWidth: '1px'
}

export const UpdateIssueInfoModal = ({ children, subContent: sub_content, mainContent: main_content, onSetSubContent }: SelectUploadTypeProps) => {
    const [file, setFile] = useState<ContentFileProps | undefined>()
    const [use_main_thumbnail, setUseMainThumbnail] = useState<boolean>()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [content, setContent] = useState<SubContentProps>(sub_content)
    const { isMobile } = useScreenSize()

    useEffect(() => {
        if (use_main_thumbnail) {
            setFile(() => ({ ...file, url: main_content.thumbnail_url }))
        } else {
            if (sub_content.thumbnail_url) {
                setFile(() => ({ ...file, url: sub_content.thumbnail_url as string }))
            }
        }
    }, [use_main_thumbnail])

    useEffect(() => {
        if (file && file.url) {
            setContent(() => ({ ...content, thumbnail_url: file.url }))
        }
    }, [file])

    const getFee = () => {
        if (content.is_paid && content.price) {
            return (content.price * 20) / 100
        } else {
            return 0
        }
    }

    const save = () => {
        if (onSetSubContent) {
            onSetSubContent({ ...content })
        }
        onClose()
    }

    return (
        <>
            <Box onClick={onOpen}>
                {children}
            </Box>

            <Modal size={isMobile ? 'full' : '3xl'} isCentered={true} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={'#1F1C2C'} overflowY={'auto'}>
                    <ModalCloseButton />
                    <ModalHeader>Enter information of your series</ModalHeader>
                    <ModalBody pb={'24px'}>
                        <Stack direction={isMobile ? 'column' : 'row'} 
                            align={'start'} 
                            spacing={'24px'} 
                            w={'full'}>
                            <VStack w={'full'} align={'start'} spacing={'16px'}>
                                <UploadFileBox
                                    type={AssetType.CONTENT_COVER}
                                    file={file}
                                    setFile={(file: any) => setFile(file)}
                                    h={isMobile ? '220px' : '500px'}
                                    w={isMobile ? 'full' : '352px'}
                                />
                                <HStack w={'full'} align={'start'}>
                                    <Checkbox isChecked={use_main_thumbnail} onChange={e => setUseMainThumbnail(e.target.checked)} colorScheme={'green'}>Use main thumbnail</Checkbox>
                                </HStack>
                            </VStack>
                            <VStack w={'full'} align={'start'} spacing={'16px'} >
                                <VStack w={'full'} align={'start'}>
                                    <Text>Title</Text>
                                    <Input value={content.title} onChange={e => setContent({ ...content, title: e.target.value })} _placeholder={{ color: 'gray.400' }} variant={'filled'} placeholder={'Enter comic title'} />
                                </VStack>
                                <VStack w={'full'} align={'start'}>
                                    <Text>Brief description</Text>
                                    <Textarea value={content.description} onChange={e => setContent({ ...content, description: e.target.value })} _placeholder={{ color: 'gray.400' }} rows={4} variant={'filled'} placeholder={'Enter brief description'} resize={'none'} />
                                    <Text fontSize={'14px'} color={'gray.400'}>300 characters max</Text>
                                </VStack>
                                <VStack w={'full'} align={'start'}>
                                    <Text>Pricing & monetization</Text>
                                    <HStack w={'full'} spacing={'24px'}>
                                        <VStack onClick={() => setContent({ ...content, is_paid: false })} {...!content.is_paid ? active_pricing_style : pricing_style}>
                                            <Center w={'50px'} h={'50px'} bg={'#3D3C4B'} borderRadius={'50%'}>
                                                <FiGift size={'24px'} />
                                            </Center>
                                            <Text fontSize={'14px'}>Free</Text>
                                        </VStack>
                                        <VStack onClick={() => setContent({ ...content, is_paid: true })} {...content.is_paid ? active_pricing_style : pricing_style}>
                                            <Center w={'50px'} h={'50px'} bg={'#3D3C4B'} borderRadius={'50%'}>
                                                <FiDollarSign size={'24px'} />
                                            </Center>
                                            <Text fontSize={'14px'}>Paid</Text>
                                        </VStack>
                                    </HStack>
                                </VStack>
                                {content.is_paid &&
                                    <VStack w={'full'} align={'start'}>
                                        <Input type={'number'} value={content.price} onChange={e => setContent({ ...content, price: parseFloat(e.target.value) })} _placeholder={{ color: 'gray.400' }} variant={'filled'} placeholder={'Enter amount'} />
                                        <VStack w={'full'} spacing={0} align={'start'}>
                                            <Text color={'gray.300'} fontSize={'14px'}>20% Novatoons Service fee:  <Text color={'gray.400'} as={'span'}>$ {getFee()}</Text></Text>
                                            <Text color={'gray.300'} fontSize={'14px'}>You will receive:  <Text color={'gray.400'} as={'span'}>$ {content.price ? content.price - getFee() : 0}</Text></Text>
                                        </VStack>
                                    </VStack>
                                }
                            </VStack>
                        </Stack>
                        <Stack direction={isMobile ? 'column-reverse': 'row'} 
                        pt={'24px'} 
                        spacing={'16px'}
                        w={'full'}>
                            <Button onClick={onClose} variant={'outline'} px={'24px'}>
                                Cancel
                            </Button>
                            <Button onClick={save} px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'}>
                                Save
                            </Button>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}