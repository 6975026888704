import { Avatar, Box, Button, Center, CenterProps, Grid, GridItem, HStack, IconButton, Img, StackProps, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from "@chakra-ui/react";
import { BsChatSquare, BsDownload, BsStar } from "react-icons/bs";
import { FiBookmark, FiFlag, FiMoreVertical, FiShare2, FiThumbsUp, FiX } from "react-icons/fi";
import { RiUserLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CreditCardPaymentModal } from "../../../components/CreditCardPaymentModal";
import { ContentPurchaseSuccessModal } from "../../../components/ContentPurchaseSuccessModal";
import { ContentProps, GetPublicListing, SubContentProps } from "../../../services/ContentService";
import { useScreenSize } from "../../../providers/ScreenSizeProvider";
import { ContentCard } from "../../public/components/ContentCard";

interface ContentDetailsCardProps {
    content: ContentProps;
}

const tag_style: CenterProps = {
    borderRadius: '8px',
    bg: '#2F2C3B',
    px: '18px',
    py: '12px',
    fontSize: '12px',
    fontWeight: 400,
    cursor: 'pointer',
    textTransform: 'capitalize'
}


export const ContentDetailsCard = ({ content }: ContentDetailsCardProps) => {
    const navigate = useNavigate()
    const [author_contents, setAuthorContents] = useState<any[]>([])
    const [related_contents, setRelatedContents] = useState<any[]>([])

    const { isMobile } = useScreenSize()

    useEffect(() => {
        (async () => {
            const result = await GetPublicListing({ user_id: content.owner.id })
            setAuthorContents(() => [...result])
        })();
        (async () => {
            const result = await GetPublicListing({ genres: content.genres })
            setRelatedContents(() => [...result.filter((item: any)=>item.id!==content.id)])
        })();
    }, [content])

    const viewIssue = (sub_content: SubContentProps) => {
        navigate(`/contents/read/${content.id}/${sub_content.id}`)
    }

    const onPurchase = () => {

    }

    return (
        <VStack spacing={'24px'} padding={'24px'} bg={'#262333'} borderRadius={'8px'} w={'full'}>
            <HStack w={'full'} justify={'space-between'} align={'start'}>
                <VStack spacing={0} align={'start'}>
                    <Text fontSize={'24px'} fontWeight={600}>{content.title}</Text>
                </VStack>
                <Center minW={'100px'}
                    fontSize={'14px'}
                    fontWeight={600}
                    color={'gray.200'}
                    borderRadius={'8px'}
                    py={'6px'}
                    px={'16px'}
                    bg={'rgb(255, 255, 255, 0.04)'}>
                    {content.is_paid ? `${content.price} USD` : 'Free'}
                </Center>
            </HStack>
            <HStack justify={'start'} w={'full'} spacing={'8px'}>
                {content.owner.profile_picture ?
                    <Avatar borderRadius={'4px'} src={content.owner.profile_picture} size={'xl'} />
                    :
                    <Center borderRadius={'8px'} borderWidth={'3px'} borderColor={'white'} h={'80px'} w={'80px'}>
                        <RiUserLine size={'32px'} color={'white'} />
                    </Center>
                }
                <VStack align={'start'} spacing={0}>
                    <Text color={'gray.300'}>Author</Text>
                    <Text textDecoration={'underline'} color={'gray.200'} fontWeight={600}>{content.owner.firstname} {content.owner.lastname}</Text>
                </VStack>
            </HStack>
            <HStack w={'full'} justify={'space-between'}>
                <HStack spacing={'12px'}>
                    <HStack spacing={'4px'}>
                        <IconButton aria-label={'Like'} icon={<FiThumbsUp />} />
                        <Text fontWeight={'16px'} color={'gray.200'}>0</Text>
                    </HStack>
                    <HStack spacing={'4px'}>
                        <IconButton aria-label={'Stars'} icon={<BsStar />} />
                        <Text fontWeight={'16px'} color={'gray.200'}>4.5 (120)</Text>
                    </HStack>
                </HStack>
                <HStack spacing={'12px'}>
                    {!isMobile &&
                        <>
                            <Button onClick={() => navigate(`/contents/read/${content.id}`)} size={'sm'} color={'green.400'}>Read Free pages</Button>
                            {(content.is_paid && content.sub_contents && content.sub_contents.length > 0) &&
                                <CreditCardPaymentModal subContentId={content.sub_contents[0].id as number} contentId={content.id as number} amount={content.price as number} onSuccess={onPurchase} />
                            }
                            <ContentPurchaseSuccessModal />
                        </>
                    }
                    <IconButton size={'sm'} aria-label={'more'} icon={<FiMoreVertical />} />
                </HStack>
            </HStack>
            <Text fontSize={'12px'} color={'gray.300'}>{content.description}</Text>
            <VStack w={'full'} align={'start'}>
                <HStack>
                    <Text fontSize={'12px'} color={'gray.400'}>Genres:</Text>
                    <Text fontSize={'12px'} color={'gray.300'}>{content.genres.join(',')}</Text>
                </HStack>
                <HStack>
                    <Text fontSize={'12px'} color={'gray.400'}>Tags:</Text>
                    <Text fontSize={'12px'} color={'gray.300'}>{content.tags.join(',')}</Text>
                </HStack>
            </VStack>
            <Tabs pt={isMobile ? '16px' : '32px'} colorScheme={'green'} w={'full'}>
                <TabList className={'hidden-scrollbar'} w={'full'} overflowX={'auto'} overflowY={'hidden'} pb={'2px'}>
                    <Tab fontSize={'14px'}>
                        Comments
                    </Tab>
                    <Tab minW={'220px'} fontSize={'14px'}>
                        More comics from this author
                    </Tab>
                    <Tab minW={'130px'} fontSize={'14px'}>
                        Similar comics
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel px={0}></TabPanel>
                    <TabPanel px={0}>
                        <Grid templateColumns={`repeat(${isMobile ? 2 : 4}, 1fr)`} gap={'30px'}>
                            {author_contents.map((item: any, index: number) =>
                                <GridItem key={index}>
                                    <ContentCard content={item} />
                                </GridItem>
                            )}
                        </Grid>
                    </TabPanel>
                    <TabPanel px={0}>
                        <Grid templateColumns={`repeat(${isMobile ? 2 : 4}, 1fr)`} gap={'30px'}>
                            {related_contents.map((item: any, index: number) =>
                                <GridItem key={index}>
                                    <ContentCard content={item} />
                                </GridItem>
                            )}
                        </Grid>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </VStack>
    )
}