import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogHeader, AlertDialogOverlay, IconButton, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { useRef } from "react"
import { FiEdit2 } from "react-icons/fi"
import { useScreenSize } from "../../../providers/ScreenSizeProvider";

interface SaveCoverPictureDialogProps {
    onUploadCover?: () => void;
}

export const SaveCoverPictureDialog = ({ onUploadCover }: SaveCoverPictureDialogProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef<any>()
    const { isMobile } = useScreenSize()

    const uploadCover = ()=> {
        if(onUploadCover){
            onUploadCover()
        }
        onClose()
    }

    return (
        <>
            <IconButton _hover={{bg: '#2F2C3B'}} bg={'#2F2C3B'} onClick={onOpen} aria-label={'Edit'} icon={<FiEdit2 />} />
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
                size={isMobile? 'sm': 'md'} 
            >
                <AlertDialogOverlay />

                <AlertDialogContent bg={'#1F1C2C'}>
                    <AlertDialogHeader>Update cover picture</AlertDialogHeader>
                    <AlertDialogBody pb={'32px'}>
                        <VStack spacing={'24px'} w={'full'} align={'start'}>
                            <Text onClick={onClose} fontSize={'14px'} cursor={'pointer'}>Remove current cover</Text>
                            <Text onClick={uploadCover} fontSize={'14px'} cursor={'pointer'}>Upload new cover</Text>
                        </VStack>
                    </AlertDialogBody>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )
}
