import { Box, Button, Grid, GridItem, HStack, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { SliderNextArrow } from "../../../components/SliderNextArrow"
import { SliderPrevArrow } from "../../../components/SliderPrevArrow"
import { ContentCategoryProps, GetPublicListing } from "../../../services/ContentService"
import { DefaultTemplate } from "../../../templates/DefaultTemplate"
import { ContentCard } from "../components/ContentCard"
import { ContentSlider } from "../components/ContentSlider"
import { CreatorCard } from "../components/CreatorCard"
import { HomeHero } from "../components/HomeHero"
import { useNavigate } from "react-router-dom"

export const HomePage = () => {
    const slider_settings: any = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: Math.ceil(window.innerWidth / 300),
        slidesToScroll: Math.ceil(window.innerWidth / 300),
        variableWidth: false,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />
    }
    const [featured_contents, setFeaturedContents] = useState<Array<any>>([])
    const [latest_contents, setLatestContents] = useState<Array<any>>([])
    const [spotlight_contents, setSpotlightContents] = useState<Array<any>>([])
    const [popular_contents, setPopularContents] = useState<Array<any>>([])
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            const result: any[] = await GetPublicListing({categories: [ContentCategoryProps.FEATURED]})
            setFeaturedContents(() => [...result])
        })();
        (async () => {
            const result: any[] = await GetPublicListing({categories: [ContentCategoryProps.LATEST_RELEASE]})
            setLatestContents(() => [...result])
        })();
        (async () => {
            const result: any[] = await GetPublicListing({categories: [ContentCategoryProps.IN_THE_SPOTLIGHT]})
            setSpotlightContents(() => [...result])
        })();
        (async () => {
            const result: any[] = await GetPublicListing({categories: [ContentCategoryProps.POPULAR]})
            setPopularContents(() => [...result])
        })();
    }, [])

    return (
        <DefaultTemplate hero={<HomeHero />}>
            <VStack spacing={'40px'} w={'full'} align={'center'}>
                { featured_contents.length > 0 &&
                    <ContentSlider title={'Featured Comics'} titleButtons={<Button isDisabled={featured_contents.length < 14} onClick={()=> navigate(`/category/${ContentCategoryProps.FEATURED.toLowerCase()}`)} pl={'24px'} pr={'24px'} colorScheme={'gray'} color={'green.400'}>View All</Button>}>
                        <Box w={'full'}>
                            { featured_contents.length > Math.ceil(window.innerWidth / 300) &&
                                <Slider {...slider_settings}>
                                    {featured_contents.map((item, index) =>
                                        <ContentCard key={index} content={item} />
                                    )}
                                </Slider>
                                ||
                                <HStack spacing={'16px'}>
                                    {featured_contents.map((item, index) =>
                                        <ContentCard key={index} content={item} />
                                    )}
                                </HStack>
                            }
                        </Box>
                    </ContentSlider>
                }
                
                { latest_contents.length > 0 &&
                    <ContentSlider title={'Latest Release'} titleButtons={<Button isDisabled={latest_contents.length < 14}  onClick={()=> navigate(`/category/${ContentCategoryProps.LATEST_RELEASE.toLowerCase()}`)} pl={'24px'} pr={'24px'} colorScheme={'gray'} color={'green.400'}>View All</Button>}>
                        <Box w={'full'}>
                            { latest_contents.length > Math.ceil(window.innerWidth / 300) &&
                                <Slider {...slider_settings}>
                                    {latest_contents.map((item, index) =>
                                        <ContentCard key={index} content={item} />
                                    )}
                                </Slider>
                                ||
                                <HStack spacing={'16px'}>
                                    {latest_contents.map((item, index) =>
                                        <ContentCard key={index} content={item} />
                                    )}
                                </HStack>
                            }
                        </Box>
                    </ContentSlider>
                }
                
                { spotlight_contents.length > 0 &&
                    <ContentSlider title={'In the spotlight'} titleButtons={<Button isDisabled={spotlight_contents.length < 14}  onClick={()=> navigate(`/category/${ContentCategoryProps.IN_THE_SPOTLIGHT.toLowerCase()}`)} pl={'24px'} pr={'24px'} colorScheme={'gray'} color={'green.400'}>View All</Button>}>
                        <Box w={'full'}>
                            { spotlight_contents.length > Math.ceil(window.innerWidth / 300) &&
                                <Slider {...slider_settings}>
                                    {spotlight_contents.map((item, index) =>
                                        <ContentCard key={index} content={item} />
                                    )}
                                </Slider>
                                ||
                                <HStack spacing={'16px'}>
                                    {spotlight_contents.map((item, index) =>
                                        <ContentCard key={index} content={item} />
                                    )}
                                </HStack>
                            }
                        </Box>
                    </ContentSlider>
                }

                { popular_contents.length > 0 &&
                    <ContentSlider title={'Popular Comics'} subTitle={'Explore the 10 Hottest Hits in Comics Today!'} titleButtons={<Button isDisabled={popular_contents.length < 14}  onClick={()=> navigate(`/category/${ContentCategoryProps.POPULAR.toLowerCase()}`)} pl={'24px'} pr={'24px'} colorScheme={'gray'} color={'green.400'}>View All</Button>}>
                        <Box w={'full'}>
                            { popular_contents.length > Math.ceil(window.innerWidth / 300) &&
                                <Slider {...slider_settings}>
                                    {popular_contents.map((item, index) =>
                                        <ContentCard key={index} content={item} />
                                    )}
                                </Slider>
                                ||
                                <HStack spacing={'16px'}>
                                    {popular_contents.map((item, index) =>
                                        <ContentCard key={index} content={item} />
                                    )}
                                </HStack>
                            }
                        </Box>
                    </ContentSlider>
                }
                
                
                {/* <ContentSlider title={'Top Creators'} subTitle={'Meet our exceptional creators shaping the comicverse! Dive into their captivating worlds'}>
                    <HStack w={'full'} spacing={'20px'}>
                        <CreatorCard size={'lg'} />
                        <Grid templateColumns='repeat(5, 1fr)' gap={'20px'}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) =>
                                <GridItem key={index}>
                                    <CreatorCard size={'md'} />
                                </GridItem>
                            )
                            }
                        </Grid>
                    </HStack>
                </ContentSlider> */}
            </VStack>
        </DefaultTemplate>
    )
}