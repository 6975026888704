import { Box, Button, HStack, Img, VStack } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { SelectGenreCard } from "../components/SelectGenreCard"
import { useState } from "react"
import { SelectAgeRangeCard } from "../components/SelectAgeRangeCard"
import { UpdatePreference } from "../../../services/AccountService"
import { pluck } from "underscore"

export const SavePreferencePage = () => {
    const navigate = useNavigate()
    const [genres, setGenres] = useState<any[]>([])
    const [step, setStep] = useState<number>(1)
    const percent_slice = 33

    const next = (genres: any[]) => {
        setStep(() => 2)
        setGenres(() => genres)
    }

    const save = async (age_range: any[]) => {
        
        await UpdatePreference({
            genres: pluck(genres, 'code'),
            age_range: pluck(age_range, 'code')
        })
        window.location.href = `/?ct=${new Date().getTime()}`
    }

    const skip = async () => {
        await UpdatePreference({
            genres: pluck(genres, 'code'),
            age_range: []
        })
        window.location.href = `/?ct=${new Date().getTime()}`
    }

    return (
        <VStack w={'full'} spacing={'80px'}>
            <VStack w={'full'} spacing={0}>
                <HStack h={'80px'} w={'full'} align={'center'} justify={'space-between'} px={'40px'}>
                    <Img cursor={'pointer'} onClick={() => navigate('/')} h={'40px'} src="/assets/images/logo-vertical.png" />
                    <Button onClick={skip} color={'green.400'}>Skip</Button>
                </HStack>
                <HStack w={'full'} spacing={0} h={'4px'}>
                    <Box bg={'green.400'} h={'full'} w={`${percent_slice * step}%`}></Box>
                    <Box bg={'#2F2C3B'} h={'full'} w={`${100 - (percent_slice * step)}%`}></Box>
                </HStack>
            </VStack>
            {step === 1 &&
                <SelectGenreCard setGenres={next} />
            }
            {step === 2 &&
                <SelectAgeRangeCard setAgeRange={save} />
            }
        </VStack>

    )
}