import { Box, Button, HStack, Stack, Text, VStack } from "@chakra-ui/react"
import { ReactNode } from "react";
import { FiBookmark, FiEye } from "react-icons/fi"
import { useScreenSize } from "../../../providers/ScreenSizeProvider";

interface SaveProgressBarProps {
    title: string;
    value: number;
    children?: ReactNode
}

const bar_style = {
    borderWidth: '4px',
    borderColor: '#F2F4F7',
    opacity: 0.25,
    w: 'full'
}

const active_bar_style = {
    ...bar_style,
    opacity: 1,
    borderColor: 'green.400'
}

export const SaveProgressBar = ({ title, value, children }: SaveProgressBarProps) => {
    const { isMobile } = useScreenSize()
    return (
        <Stack direction={isMobile ? 'column' : 'row'} w={'full'} spacing={'24px'}>
            <VStack w={'full'}>
                <HStack w={'full'} justify={'space-between'}>
                    <Text textTransform={'uppercase'} fontWeight={500} fontSize={'md'}>
                        {title}
                    </Text>
                    <Text fontWeight={500} fontSize={'md'}>{value}/3</Text>
                </HStack>
                <HStack w={'full'} spacing={'12px'}>
                    <Box {...value >= 1 ? active_bar_style : bar_style} />
                    <Box {...value >= 2 ? active_bar_style : bar_style} />
                    <Box {...value >= 3 ? active_bar_style : bar_style} />
                </HStack>
            </VStack>
            {children}
        </Stack>
    )
}