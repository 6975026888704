import { Box, Button, HStack, Img, Link, Text, useToast, VStack } from "@chakra-ui/react"
import { Storage } from '@ionic/storage'
import { useGoogleLogin } from "@react-oauth/google"
import { useContext, useState } from "react"
import { FaFacebook, FaGoogle } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { polly } from "../../../mocks/MockServer"
import { UserContext } from "../../../providers/UserProvider"
import { LoginWithFacebook, LoginWithGoogle } from "../../../services/AuthService"
import { AuthTemplate } from "../../../templates/AuthTemplate"
import { useScreenSize } from "../../../providers/ScreenSizeProvider"

const store = new Storage({
    name: process.env.REACT_APP_STORAGE_NAME
})

declare var FB: any;

export const RegisterPage = () => {
    const navigate = useNavigate()
    const { setUser } = useContext(UserContext)
    const { isMobile } = useScreenSize()

    const [is_logging_in, setIsLoggingIn] = useState<boolean>()

    const loginWithFacebook = async () => {
        const storage = await store.create();
        const access_token = await getFacebookToken()
        setIsLoggingIn(() => true)
        const user = await LoginWithFacebook(access_token as string)
        await storage.set(`access_token`, user.access_token)
        setUser(() => user)
        window.location.href = '/'
    }

    const getFacebookToken = () => {
        polly.pause()
        return new Promise((resolve: any, reject: any) => {
            FB.login((response: any) => {
                const { authResponse } = response
                const { accessToken } = authResponse
                resolve(accessToken)
                polly.play()
            }, { scope: 'email' })
        })
    }

    const loginWithGoogle = useGoogleLogin({
        onSuccess: async (response: any) => {
            const storage = await store.create();
            const { access_token } = response
            const user = await LoginWithGoogle(access_token)
            await storage.set(`access_token`, user.access_token)
            setUser(() => user)
            window.location.href = '/'
        }
    })

    return (
        <AuthTemplate>
            <VStack spacing={'32px'}
                align={'start'}
                px={'24px'}
                py={!isMobile ? '20px' : '60px'}
                w={!isMobile ? '500px' : 'full'}
                h={!isMobile ? '600px' : 'full'}
                minH={'500px'}
                bg={'#262333'}
                border={'1px solid #2F2C3B'}
                borderRadius={'8px'}>
                <VStack spacing={'20px'} w={'full'} align={'start'}>
                    <Img h={'50px'} cursor={'pointer'} onClick={() => navigate(`/`)} src={'/assets/images/logo-vertical.png'} />
                    <Text fontWeight={600} fontSize={'2xl'}>Welcome to Novatoons</Text>
                </VStack>
                <VStack w={'full'} spacing={'16px'}>
                    <Button onClick={() => loginWithGoogle()} leftIcon={<FaGoogle />} w={'full'}>
                        Sign Up with Google
                    </Button>
                    <Button onClick={() => loginWithFacebook()} leftIcon={<FaFacebook />} w={'full'}>
                        Sign Up with Facebook
                    </Button>
                    <HStack py={'16px'} alignSelf={'stretch'} align={'center'} spacing={'24px'}>
                        <Box h={'1px'} bg={'#fff'} w={'100%'}></Box>
                        <Text>or</Text>
                        <Box h={'1px'} bg={'#fff'} w={'100%'}></Box>
                    </HStack>
                    <Button onClick={() => navigate('/register/email')} _hover={{ bg: 'green.300' }} w={'full'} bg={'green.400'} color={'black'}>
                        Sign Up with Email
                    </Button>
                    <HStack align={'center'} justify={'center'} w={'full'}>
                        <Text fontSize={'sm'}>Already have an account?</Text>
                        <Link fontWeight={600} onClick={() => navigate('/login')}>Login</Link>
                    </HStack>
                </VStack>
                <HStack justify={'center'} w={'full'}>
                    <Text fontSize={'sm'} w={'312px'} textAlign={'center'}>
                        By signing up, you agree to Novatoons' <Text fontWeight={600} as={'span'}>Terms of Service and Privacy Policy</Text>
                    </Text>
                </HStack>
            </VStack>
        </AuthTemplate>
    )
}