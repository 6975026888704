import { Request, Response } from '@pollyjs/core'
import { findWhere } from 'underscore'
import { GetJson } from './MockHelper'

export const GetUnverified = async (req: Request, res: Response) => {
    try {
        const token = req.params.token as string
        const users = await GetJson(`users`)
        const user = findWhere(users, {
            verification_token: token
        })
        if (user) {
            const { password, verification_code, ..._user } = user
            res.status(200).json({ ..._user })
        } else {
            throw new Error(`We can't find account associated with the token`)
        }
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const GetCurrent = async (req: Request, res: Response) => {
    try {
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        if (access_token) {
            const users = await GetJson(`users`)
            const user = findWhere(users, {
                access_token
            })
            if (user) {
                const { password, ..._user } = user
                res.status(200).json(_user)
            } else {
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }

    } catch (e: any) {
        res.status(500).json({ message: e.messasge })
    }
}