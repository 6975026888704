import { Box, BoxProps } from "@chakra-ui/react"
import { ReactNode } from "react"
import { useScreenSize } from "../providers/ScreenSizeProvider";

interface PageContentProps extends BoxProps {
    children: ReactNode;
}

export const PageContent = ({ children, ...rest }: PageContentProps) => {
    const { isMobile } = useScreenSize()
    return (
        <Box w={'full'} pt={!isMobile ? '72px' : '24px'} pb={'24px'} px={!isMobile ? '80px' : '16px'} {...rest}>
            {children}
        </Box>
    )
}