import { Box, Center, HStack, IconButton, StackProps, Text, Textarea, VStack } from "@chakra-ui/react"
import { useState } from "react";
import { FiFlag, FiSend, FiThumbsDown, FiThumbsUp, FiX } from "react-icons/fi"
import { RiUserLine } from "react-icons/ri"
import { AddComment, SubContentProps } from "../../../services/ContentService";
import moment from 'moment'

interface ContentCommentBoxProps extends StackProps {
    onClose?: () => void;
    subContent: SubContentProps;
}

export const ContentCommentBox = ({ subContent: sub_content, onClose }: ContentCommentBoxProps) => {
    const [message, setMessage] = useState<string>('')
    const [comments, setComments] = useState<any[]>(() => [...sub_content.comments ? sub_content.comments : []])


    const send = async () => {
        const result = await AddComment(sub_content.parent_id as number, sub_content.id as number, message)
        setComments(()=> result)
        setMessage(``)
    }

    const formatDate = (date: Date)=> {
        return moment(date).format(`MMM D h:mm a`)
    }

    return (
        <VStack borderRadius={'8px'} w={'440px'} bg={'#262333'}>
            <HStack borderBottomWidth={'1px'} borderColor={'#1F1C2C'} p={'24px'} w={'full'} justify={'space-between'}>
                <Text fontSize={'24px'} fontWeight={600}>Comments</Text>
                <IconButton onClick={onClose} aria-label={'Info'} icon={<FiX />} />
            </HStack>
            <VStack w={'full'} maxH={'350px'} overflowY={'scroll'}>
                {comments.map((item: any, index: number) =>
                    <VStack key={index} borderBottomWidth={'1px'} borderColor={'#1F1C2C'} spacing={'8px'} p={'24px'} w={'full'}>
                        <HStack w={'full'} justify={'space-between'}>
                            <HStack>
                                <Center borderRadius={'8px'} borderWidth={'2px'} borderColor={'white'} h={'32px'} w={'32px'}>
                                    <RiUserLine size={'16px'} color={'white'} />
                                </Center>
                                <Text fontSize={'14px'} color={'gray.300'} fontWeight={600}>
                                    {item.owner.firstname} {item.owner.lastname}
                                </Text>
                            </HStack>
                            <Text fontSize={'14px'} color={'gray.300'}>{formatDate(item.created_at)}</Text>
                        </HStack>
                        <Text w={'full'} fontSize={'14px'} color={'gray.200'}>
                            {item.message}
                        </Text>
                        <HStack w={'full'} justify={'end'} spacing={'12px'}>
                            <HStack spacing={'6px'}>
                                <IconButton size={'sm'} aria-label={'Like'} icon={<FiThumbsUp />} />
                                {/* <Text>8</Text> */}
                            </HStack>
                            <HStack spacing={'6px'}>
                                <IconButton size={'sm'} aria-label={'Dislike'} icon={<FiThumbsDown />} />
                                {/* <Text>3</Text> */}
                            </HStack>
                            <IconButton size={'sm'} aria-label={'Report'} icon={<FiFlag />} />
                        </HStack>
                    </VStack>
                )
                }
            </VStack>
            <HStack bg={'#2F2C3B'} p={'24px'} w={'full'} align={'start'}>
                <Textarea value={message} onChange={e => setMessage(e.target.value)} borderWidth={0} outline={'none'} bg={'#2F2C3B'} resize={'none'} placeholder='Type a message...' />
                <IconButton onClick={send} size={'lg'} _hover={{ bg: 'green.300' }} bg={'green.400'} aria-label={'Send'} icon={<FiSend color={'#333'} />} />
            </HStack>
        </VStack>
    )
}