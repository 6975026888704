import { Button, ButtonProps, Grid, GridItem, HStack, Input, InputGroup, InputLeftElement, Stack, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { MdFilterList } from "react-icons/md";
import { RiStackLine } from "react-icons/ri";
import { pluck, where } from "underscore";
import { GetPublicListing } from "../../../services/ContentService";
import { DefaultTemplate } from "../../../templates/DefaultTemplate";
import { ContentCard } from "../components/ContentCard";
import { ExploreFilter } from "../components/ExploreFilter";
import { Hero } from "../components/Hero";
import { useScreenSize } from "../../../providers/ScreenSizeProvider";

const active_button_style: ButtonProps = {
    bg: 'green.400',
    color: 'black',
    _hover: {
        bg: 'green.300'
    }
}

export const ExplorePage = () => {
    const [contents, setContents] = useState<Array<any>>([])
    const [filters, setFilters] = useState<Array<any>>([])
    const [has_filters, setHasFilters] = useState<boolean>()
    const [type, setType] = useState<string>('ALL')
    const [keyword, setKeyword] = useState<string>('')
    const { isMobile } = useScreenSize()

    useEffect(() => {
        (async () => {
            const result = await GetPublicListing()
            setContents(() => [...result])
            console.log(result, 'contents')
        })();
    }, [])

    useEffect(() => {
        (async () => {
            const genres = pluck(where(filters, { type: 'genre' }), 'value')
            const tags = pluck(where(filters, { type: 'tag' }), 'value')
            const audience_types = pluck(where(filters, { type: 'audience_type' }), 'value')
            const prices = pluck(where(filters, { type: 'prices' }), 'value')
            const result = await GetPublicListing({ genres, tags, audience_types, prices, type, keyword })
            setContents(() => [...result])
        })();
    }, [filters, type, keyword])

    const toggleFilters = () => {
        setHasFilters(() => !has_filters)
    }
    return (
        <DefaultTemplate hero={<Hero text={'Explore Comics'} />} activeNav={'EXPLORE'}>
            <VStack minH={!isMobile ? '1000px' : '400px'} w={'full'} align={'center'}>
                <VStack spacing={'40px'} w={'full'} maxW={'1460px'} align={'start'}>
                    <Stack spacing={'16px'} direction={isMobile ? 'column-reverse': 'row'} w={'full'} justify={'space-between'}>
                        <HStack spacing={'16px'}>
                            <Button w={'full'} onClick={toggleFilters} px={'24px'} leftIcon={<MdFilterList />} colorScheme={'gray'} color={'white'}>Filters</Button>
                            <Button w={'full'} px={'24px'} leftIcon={<RiStackLine />} colorScheme={'gray'} color={'white'}>Sort by</Button>
                        </HStack>
                        <InputGroup variant={'filled'}>
                            <InputLeftElement pointerEvents={'none'}>
                                <FiSearch />
                            </InputLeftElement>
                            <Input flexGrow={1} maxW={'380px'} _focus={{ outline: "none" }} type={'text'} placeholder={'Search'} value={keyword} onChange={e => setKeyword(e.target.value)} />
                        </InputGroup>
                        <HStack spacing={'16px'}>
                            <Button w={isMobile ? 'full': 'inherit'} onClick={() => setType('ALL')} {...type === 'ALL' ? active_button_style : {}} colorScheme={'gray'}>All</Button>
                            <Button w={isMobile ? 'full': 'inherit'} onClick={() => setType('SINGLE')} {...type === 'SINGLE' ? active_button_style : {}} colorScheme={'gray'} color={'white'}>Single Titled</Button>
                            <Button w={isMobile ? 'full': 'inherit'} onClick={() => setType('SERIES')} {...type === 'SERIES' ? active_button_style : {}} colorScheme={'gray'} color={'white'}>Series</Button>
                        </HStack>
                    </Stack>

                    <HStack justify={'space-between'} w={'full'} align={'start'}>
                        {has_filters &&
                            <ExploreFilter hasGenres={true} filters={filters} setFilters={setFilters} />
                        }

                        <Grid templateColumns={`repeat(${!isMobile ? Math.ceil(window.innerWidth / 300) : 2}, 1fr)`} gap={!isMobile ? '30px' : '24px'}>
                            {contents.map((item: any, index: number) =>
                                <GridItem key={index}>
                                    <ContentCard content={item} />
                                </GridItem>
                            )}
                        </Grid>
                    </HStack>
                </VStack>
            </VStack>
        </DefaultTemplate>
    )
}