import { HStack, Img, StackProps, Text, VStack } from "@chakra-ui/react"
import { findWhere } from "underscore";

interface ContentStatsCardProps extends StackProps {
    type: 'USER_ENGAGEMENT' | 'SALES' | 'EARNINGS' | "PAYOUTS";
    value: number;
    stats: Array<{ label: string, value: number }>
}

export const ContentStatsCard = ({ type, value, stats, ...rest }: ContentStatsCardProps) => {
    const stat_types = [
        {
            code: 'USER_ENGAGEMENT',
            title: 'User Engagements',
            background_image: '/assets/images/account/user-engagements.png'
        },
        {
            code: 'SALES',
            title: 'Total Sales Made',
            background_image: '/assets/images/account/sales.png'
        },
        {
            code: 'EARNINGS',
            title: 'Total Earnings',
            background_image: '/assets/images/account/earnings.png'
        },
        {
            code: 'PAYOUTS',
            title: 'Total Payouts',
            background_image: '/assets/images/account/sales.png'
        },
    ]

    return (
        <VStack pos={'relative'} spacing={'10px'} padding={'24px'} bg={'#262333'} borderRadius={'8px'} w={'367px'} align={'start'}  {...rest} >
            <Img borderTopRightRadius={'8px'} top={0} right={0} w={'80px'} pos={'absolute'} src={findWhere(stat_types,  {code: type})?.background_image} />
            <Text fontSize={'14px'}>{ findWhere(stat_types,  {code: type})?.title }</Text>
            <Text fontSize={'24px'} fontWeight={600}>
                { value }
            </Text>
            <HStack>
                { stats.map((item: any, index: number)=>
                    <VStack key={index} borderRadius={'4px'} bg={'#2F2C3B'} w={'100px'} align={'start'} px={'8px'} py={'4px'}>
                        <Text fontSize={'12px'} color={'gray.200'}>{ item.label }</Text>
                        <Text fontSize={'14px'} color={'gray.200'}>{ item.value }</Text>
                    </VStack>
                )}
            </HStack>
        </VStack>
    )
}