import { Box, BoxProps, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text, VStack, useDisclosure } from "@chakra-ui/react"
import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import { useScreenSize } from "../../../providers/ScreenSizeProvider"

interface SelectUploadTypeProps extends BoxProps {
    children: ReactNode
}

export const SelectUploadType = ({ children, ...rest }: SelectUploadTypeProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isMobile } = useScreenSize()
    const navigate = useNavigate()

    return (
        <>
            <Box onClick={onOpen} {...rest}>
                {children}
            </Box>

            <Modal size={isMobile ? 'full' : 'xl'} isCentered={true} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={'#1F1C2C'}>
                    <ModalHeader>Upload a new Comic</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={'24px'}>
                        <Stack justify={'center'} direction={isMobile?'column':'row'} w={'full'} spacing={'20px'}>
                            <VStack onClick={() => navigate('/contents/save')} spacing={'24px'} cursor={'pointer'} minH={'230px'} borderRadius={'8px'} bg={'#262333'} p={'16px'} w={'full'}>
                                <Image w={'full'} minH={'120px'} src={'/assets/images/contents/book-shelf-1.png'} />
                                <VStack spacing={0} w={'full'} align={'center'}>
                                    <Text fontWeight={500} fontSize={'md'}>Upload a new Comic</Text>
                                    <Text textAlign={'center'} fontSize={'sm'} color={'gray.300'}>
                                        Submit a new comic, whether it's a standalone title or part of a series
                                    </Text>
                                </VStack>
                            </VStack>
                            <VStack onClick={() => navigate('/account/contents')} spacing={'24px'} cursor={'pointer'} minH={'230px'} borderRadius={'8px'} bg={'#262333'} p={'16px'} w={'full'}>
                                <Image w={'full'} h={'120px'} src={'/assets/images/contents/book-shelf-2.png'} />
                                <VStack spacing={0} w={'full'} align={'center'}>
                                    <Text fontWeight={500} fontSize={'md'}>Add a new Issue</Text>
                                    <Text textAlign={'center'} fontSize={'sm'} color={'gray.300'}>
                                        Add a new issue to an existing series.
                                    </Text>
                                </VStack>
                            </VStack>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}