import { Box, Button, ButtonProps, Grid, GridItem, HStack, Input, InputGroup, InputLeftElement, Stack, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { MdFilterList } from "react-icons/md";
import { RiStackLine } from "react-icons/ri";
import { findIndex, findWhere, pluck, where } from "underscore";
import { GetGenreListing, GetPublicListing } from "../../../services/ContentService";
import { DefaultTemplate } from "../../../templates/DefaultTemplate";
import { ContentCard } from "../components/ContentCard";
import { ExploreFilter } from "../components/ExploreFilter";
import { Hero } from "../components/Hero";
import { useParams } from "react-router-dom";
import { useScreenSize } from "../../../providers/ScreenSizeProvider";

const active_filter_style: ButtonProps = {
    bg: 'green.400',
    color: 'black',
    _hover: {
        bg: 'green.300'
    }
}

export const GenrePage = () => {
    const [contents, setContents] = useState<Array<any>>([])
    const [filters, setFilters] = useState<Array<any>>([])
    const [has_filters, setHasFilters] = useState<boolean>()
    const [type, setType] = useState<string>('ALL')
    const [keyword, setKeyword] = useState<string>('')
    const [genres, setGenres] = useState<Array<any>>([])
    const { genre } = useParams()
    const [initial_genre, setInitialGenre] = useState<any>(genre)
    const { isMobile } = useScreenSize()

    useEffect(() => {
        (async () => {
            const result = await GetGenreListing()
            setGenres(() => [...result])
        })();
    }, [])

    useEffect(() => {
        (async () => {
            const genres = pluck(where(filters, { type: 'genre' }), 'value')
            const tags = pluck(where(filters, { type: 'tag' }), 'value')
            const audience_types = pluck(where(filters, { type: 'audience_type' }), 'value')
            const prices = pluck(where(filters, { type: 'prices' }), 'value')
            if (initial_genre) {
                genres.push(initial_genre.toUpperCase())
                setFilters(() => [...filters, { type: 'genre', value: initial_genre.toUpperCase() }])
                setInitialGenre(() => '')
            }
            const result = await GetPublicListing({ genres, tags, audience_types, prices, type, keyword })
            setContents(() => [...result])
        })();
    }, [filters, type, keyword, initial_genre])

    const toggleFilters = () => {
        setHasFilters(() => !has_filters)
    }

    const changeFilters = (type: string, value: string) => {
        if (setFilters) {
            const _filters = [...filters]
            const index = findIndex(_filters, item => item.type === type && item.value === value)
            if (index > -1) {
                _filters.splice(index, 1)
            } else {
                _filters.push({ type, value })
            }
            setFilters([..._filters])
        }
    }

    const inFilters = (type: string, value: string) => {
        const exist = findWhere(filters, { type, value })
        return exist ? true : false
    }

    return (
        <DefaultTemplate hero={<Hero text={'Genre'} />} activeNav={'GENRE'}>
            <VStack minH={'1000px'} w={'full'} align={'center'}>
                <VStack spacing={'40px'} w={'full'} maxW={'1460px'} align={'start'}>
                     <VStack w={'full'} spacing={'16px'}>
                        <Box overflowX={'auto'} w={'full'}>
                            <HStack spacing={'16px'}>
                                {genres.map((item: any, index: number) =>
                                    <Button minW={'120px'} bg={'#262333'} {...inFilters('genre', item.code) ? active_filter_style: {}} onClick={()=> changeFilters('genre', item.code)}  key={index} colorScheme={'gray'} color={'white'}>
                                        { item.name }
                                    </Button>
                                )}
                            </HStack>
                        </Box>
                        <Stack spacing={'16px'} direction={isMobile ? 'column-reverse' : 'row'} w={'full'} justify={'space-between'}>
                            <HStack spacing={'16px'}>
                                <Button w={'full'} onClick={toggleFilters} px={'24px'} leftIcon={<MdFilterList />} colorScheme={'gray'} color={'white'}>Filters</Button>
                                <Button w={'full'} px={'24px'} leftIcon={<RiStackLine />} colorScheme={'gray'} color={'white'}>Sort by</Button>
                            </HStack>
                            <InputGroup variant={'filled'}>
                                <InputLeftElement pointerEvents={'none'}>
                                    <FiSearch />
                                </InputLeftElement>
                                <Input maxW={'380px'} _focus={{ outline: "none" }} type={'text'} placeholder={'Search'} value={keyword} onChange={e => setKeyword(e.target.value)} />
                            </InputGroup>
                        </Stack>
                     </VStack>
                    <HStack justify={'space-between'} w={'full'} align={'start'}>
                        {has_filters &&
                            <ExploreFilter filters={filters} setFilters={setFilters} />
                        }

                        <Grid templateColumns={`repeat(${isMobile ? 2 : Math.ceil(window.innerWidth / 300)}, 1fr)`} gap={'30px'}>
                            {contents.map((item: any, index: number) =>
                                <GridItem key={index}>
                                    <ContentCard content={item} />
                                </GridItem>
                            )}
                        </Grid>
                    </HStack>
                </VStack>
            </VStack>
        </DefaultTemplate>
    )
}