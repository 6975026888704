import { Box, VStack } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FiChevronLeft } from "react-icons/fi";


const ArrowContainer = styled(VStack)`
    height: 40px;
    width: 40px;
    justify-content: center;
    position: absolute;
    top: 35%;
    left: -25px;
    cursor: pointer;
    z-index: 100;
    &:hover{
        .circle-bg{
            opacity: 1;
        }
    }
`

const CircleBackground = styled(Box)`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    border-radius: 50%;
    opacity: 0.6;
`

export const SliderPrevArrow = (props: any) => {
    const { onClick } = props;
    return (
        <ArrowContainer onClick={onClick}>
            <CircleBackground className={'circle-bg'} />
            <FiChevronLeft style={{ position: 'relative' }} />
        </ArrowContainer>
    );
}