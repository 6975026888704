import FetchAdapter from '@pollyjs/adapter-fetch';
import { Polly } from '@pollyjs/core';
import { GenerateUploadUrl, Save as SaveAsset } from './AssetMock';
import { Login, LoginWithFacebook, LoginWithGoogle, Register, Verify } from './AuthMock';
import { DeleteSubContent, Get as GetContent, GetDraft as GetDraftContent, GetListing as GetContentListing, GetPublicListing, Save as SaveContent, SaveSubContent, GetGenreListing, GetTagListing, AddComment } from './ContentMock';
import { GetCurrent, GetUnverified } from './UserMock';
import { ChangePassword, RequestRecover, UpdateBasicInfo, UpdatePreference, UpdateShippingAddress } from './AccountMock';
import { AddPaymentMethod, Confirm, GetCreditCardListing, Initialize, InitializeAccount, InitializePayoutAccount, RequestPayout } from './PaymentMock';
import { GetListing as GetTransactionListing } from './TransactionMock';

Polly.register(FetchAdapter)

export const polly = new Polly('Mock API', {
    mode: 'passthrough',
    adapters: ['fetch']
});

export const MockServer = async () => {
    const { server } = polly;

    //  set auth routes
    server.post(`${process.env.REACT_APP_API_URL}/auth/register`).intercept(Register)
    server.post(`${process.env.REACT_APP_API_URL}/auth/verify`).intercept(Verify)
    server.post(`${process.env.REACT_APP_API_URL}/auth/login`).intercept(Login)
    server.post(`${process.env.REACT_APP_API_URL}/auth/login/facebook`).intercept(LoginWithFacebook)
    server.post(`${process.env.REACT_APP_API_URL}/auth/login/google`).intercept(LoginWithGoogle)

    //  set account routes
    server.post(`${process.env.REACT_APP_API_URL}/accounts/recover/request`).intercept(RequestRecover)
    server.post(`${process.env.REACT_APP_API_URL}/accounts/password/change`).intercept(ChangePassword)
    server.post(`${process.env.REACT_APP_API_URL}/accounts/basic-info/update`).intercept(UpdateBasicInfo)
    server.post(`${process.env.REACT_APP_API_URL}/accounts/shipping-address/update`).intercept(UpdateShippingAddress)
    server.post(`${process.env.REACT_APP_API_URL}/accounts/preference/update`).intercept(UpdatePreference)

    //  set user routes
    server.get(`${process.env.REACT_APP_API_URL}/users/unverified/:token`).intercept(GetUnverified)
    server.get(`${process.env.REACT_APP_API_URL}/users/current`).intercept(GetCurrent)

    //  set content routes
    server.post(`${process.env.REACT_APP_API_URL}/contents/save`).intercept(SaveContent)
    server.get(`${process.env.REACT_APP_API_URL}/contents/:id`).intercept(GetContent)
    server.get(`${process.env.REACT_APP_API_URL}/contents/draft/:id`).intercept(GetDraftContent)
    server.get(`${process.env.REACT_APP_API_URL}/contents/listing`).intercept(GetContentListing)
    server.get(`${process.env.REACT_APP_API_URL}/contents/public/listing`).intercept(GetPublicListing)
    server.get(`${process.env.REACT_APP_API_URL}/contents/genres/listing`).intercept(GetGenreListing)
    server.get(`${process.env.REACT_APP_API_URL}/contents/tags/listing`).intercept(GetTagListing)
    server.post(`${process.env.REACT_APP_API_URL}/contents/comments/add`).intercept(AddComment)

    //  set sub content routes
    server.post(`${process.env.REACT_APP_API_URL}/contents/subs/save`).intercept(SaveSubContent)
    server.delete(`${process.env.REACT_APP_API_URL}/contents/subs/:id`).intercept(DeleteSubContent)

    //  set asset routes
    server.post(`${process.env.REACT_APP_API_URL}/assets/url/generate`).intercept(GenerateUploadUrl)
    server.post(`${process.env.REACT_APP_API_URL}/assets/save`).intercept(SaveAsset)

    //  set payment routes
    server.get(`${process.env.REACT_APP_API_URL}/payments/creditcards/listing`).intercept(GetCreditCardListing)
    server.post(`${process.env.REACT_APP_API_URL}/payments/accounts/initialize`).intercept(InitializeAccount)
    server.post(`${process.env.REACT_APP_API_URL}/payments/accounts/add-payment-method`).intercept(AddPaymentMethod)
    server.post(`${process.env.REACT_APP_API_URL}/payments/initialize`).intercept(Initialize)
    server.post(`${process.env.REACT_APP_API_URL}/payments/confirm`).intercept(Confirm)
    server.post(`${process.env.REACT_APP_API_URL}/payments/payout/accounts/initialize`).intercept(InitializePayoutAccount)
    server.post(`${process.env.REACT_APP_API_URL}/payments/payout/request`).intercept(RequestPayout)

    //  set transactions route
    server.get(`${process.env.REACT_APP_API_URL}/transactions/listing`).intercept(GetTransactionListing)
}