import { BoxProps, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Header } from "../components/Header";
import { PageContent } from "../components/PageContent";
import { useScreenSize } from "../providers/ScreenSizeProvider";

interface MainTemplateProps extends BoxProps  {
    children: ReactNode;
    activeNav?: 'HOME' | 'EXPLORE' | 'GENRE' | 'MERCHANDISE' | 'ABOUT_US' | 'HELP_CENTER';
    hideHeader?: boolean;
}

export const MainTemplate = ({ children, activeNav = 'HOME', hideHeader: hide_header, ...rest }: MainTemplateProps) => {
    const { isMobile } = useScreenSize() 
    return (
        <VStack w={'full'}>
            { !hide_header &&
                <Header isFloating={true} activeNav={activeNav} />
            }
            <PageContent pt={hide_header ? '16px': (isMobile ? '110px' : '150px')} {...rest}>
                {children}
            </PageContent>
        </VStack>
    )
}