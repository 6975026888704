import { Request, Response } from '@pollyjs/core'
import { GetJson } from './MockHelper'
import { filter, findIndex, findWhere } from 'underscore'

export const GetListing = async (req: Request, res: Response) => {
    try {
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        if (access_token) {
            const [users, transactions] = await Promise.all([
                GetJson(`users`),
                GetJson(`transactions`)
            ])
            const index = findIndex(users, (item: any) => item.access_token === access_token)
            if(index > -1){
                const user = users[index]
                const _transactions = filter(transactions, item => item.user.id === user.id).reverse()
                res.status(200).json([..._transactions])
            }else{
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {
        console.log(e, 'wew')
        res.status(500).json({ message: e.message })
    }
}