import { Box, BoxProps, Center, Fade, GridItem, GridItemProps, HStack, Img, Text, useDisclosure, VStack } from "@chakra-ui/react"

interface GenreCardProps extends GridItemProps{
    genre: any;
}

const backdrop_style: BoxProps = {
    bg: 'white',
    opacity: 0.1,
    pos: 'absolute',
    w: 'full',
    h: 'full',
    zIndex: 10
}

const genre_backdrop_style: BoxProps = {
    ...backdrop_style,
    bg: 'black',
    opacity: 0.6
}

export const GenreCard = ({ genre: item, ...rest}: GenreCardProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <GridItem onMouseOver={onOpen} 
                onMouseOut={onClose} 
                cursor={'pointer'} 
                overflow={'hidden'} 
                borderRadius={'16px'} 
                h={'150px'} 
                w={'180px'} 
                pos={'relative'}
                {...rest}>
            
            <Img pos={'absolute'} w={'full'} src={item.picture_url} />
            { !isOpen &&
                <Fade style={{height: '100%'}} in={true}>
                    <Box {...genre_backdrop_style}></Box>
                    <HStack zIndex={10} pos={'relative'} w={'full'} h={'full'} justify={'center'}>
                        <Text fontSize={'2xl'}>{item.name}</Text>
                    </HStack>
                </Fade>
            }
            
            { isOpen &&
                <VStack bg={'transparent'} zIndex={9} justify={'end'} pos={'relative'} w={'full'} h={'full'}>
                    <VStack pos={'relative'} h={'36px'} w={'full'} justify={'center'}>
                        <Box {...genre_backdrop_style}></Box>
                        <Text zIndex={10} fontSize={'16px'}>{item.name}</Text>
                    </VStack>
                </VStack>
            }
        </GridItem>
    )
}