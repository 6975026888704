import { Button, HStack, StackProps, Text, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { GetGenreListing } from "../../../services/ContentService";
import { AudienceType } from "../../contents/pages/SaveReaderSettingsPage";

interface SelectAgeRangeCardProps extends StackProps {
    setAgeRange?: (genres: any[]) => void;
}

export const SelectAgeRangeCard = ({ setAgeRange: setSelectedAgeRange }: SelectAgeRangeCardProps) => {
    const [age_range, setAgeRange] = useState<any[]>([
        {
            name: '3 - 12 years',
            code: AudienceType.KIDS
        },
        {
            name: '13 - 17  years',
            code: AudienceType.TEENS
        },
        {
            name: '18 - above',
            code: AudienceType.ADULTS
        }
    ])

    const toggle = (genre: any) => {
        setAgeRange(() => [...age_range.map(item => ({ ...item, is_selected: genre.code === item.code ? !item.is_selected : item.is_selected }))])
    }

    const save = () => {
        if (setSelectedAgeRange) {
            setSelectedAgeRange([...age_range.filter(item => item.is_selected)])
        }
    }
    return (
        <VStack px={'20px'} py={'24px'} borderRadius={'8px'} w={'500px'} bg={'#262333'}>
            <VStack spacing={'32px'} w={'full'}>
                <VStack w={'full'} spacing={'4px'}>
                    <Text fontSize={'24px'} fontWeight={700} color={'gray.300'}>
                        What age range best represents your appropriateness for content?
                    </Text>
                    <Text fontSize={'14px'} color={'gray.400'}>
                    Please select the age range that best corresponds to the appropriateness of the content for you. This helps us tailor your experience and ensure you discover content that aligns with your preferences and comfort level.
                    </Text>
                </VStack>
                <HStack spacing={'16px'} w={'full'} align={'start'} flexWrap={'wrap'}>
                    {age_range.map((item: any, index: number) =>
                        <Button onClick={() => toggle(item)} borderColor={'gray.300'} borderWidth={item.is_selected ? '1px' : 0} key={index}>{item.name}</Button>
                    )}
                </HStack>
                <Button onClick={save} bg={'green.400'} w={'full'} colorScheme={'green'}>Proceed</Button>
            </VStack>
        </VStack>
    )
}