import { Button, ButtonProps, Grid, GridItem, HStack, Input, InputGroup, InputLeftElement, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { MdFilterList } from "react-icons/md";
import { RiStackLine } from "react-icons/ri";
import { pluck, where } from "underscore";
import { ContentCategoryProps, GetPublicListing } from "../../../services/ContentService";
import { DefaultTemplate } from "../../../templates/DefaultTemplate";
import { ContentCard } from "../components/ContentCard";
import { ExploreFilter } from "../components/ExploreFilter";
import { Hero } from "../components/Hero";
import { useParams } from "react-router-dom";

const active_button_style: ButtonProps = {
    bg: 'green.400',
    color: 'black',
    _hover: {
        bg: 'green.300'
    }
}

export const CategoryPage = () => {
    const [contents, setContents] = useState<Array<any>>([])
    const [filters, setFilters] = useState<Array<any>>([])
    const [has_filters, setHasFilters] = useState<boolean>()
    const [type, setType] = useState<string>('ALL')
    const [keyword, setKeyword] = useState<string>('')
    const { category } = useParams()
    const [title] = useState<string>(()=> {
        if(category){
            switch(category.toUpperCase()){
                case ContentCategoryProps.FEATURED:
                    return `Featured Comics`
                case ContentCategoryProps.IN_THE_SPOTLIGHT:
                    return `In The Spotlight`
                case ContentCategoryProps.LATEST_RELEASE:
                    return `Latest Release`
                case ContentCategoryProps.POPULAR:
                    return `Popular Comics`
                default:
                    return ``
            }
        }else{
            return ``
        }
        
    })

    useEffect(() => {
        (async () => {
            const genres = pluck(where(filters, { type: 'genre' }), 'value')
            const tags = pluck(where(filters, { type: 'tag' }), 'value')
            const audience_types = pluck(where(filters, { type: 'audience_type' }), 'value')
            const prices = pluck(where(filters, { type: 'prices' }), 'value')
            let categories: any[] = []
            if(category){
                categories = [category.toUpperCase()]
                // setTitle(()=> {
                //     switch(category.toUpperCase()){
                //         case ContentCategoryProps.FEATURED:
                //             return `Featured Comics`
                //         case ContentCategoryProps.IN_THE_SPOTLIGHT:
                //             return `In The Spotlight`
                //         case ContentCategoryProps.LATEST_RELEASE:
                //             return `Latest Release`
                //         case ContentCategoryProps.POPULAR:
                //             return `Popular Comics`
                //         default:
                //             return ``
                //     }
                // })
            }
            const result = await GetPublicListing({ categories, genres, tags, audience_types, prices, type, keyword })
            setContents(() => [...result])
        })();
    }, [filters, type, keyword, category])

    const toggleFilters = () => {
        setHasFilters(() => !has_filters)
    }

    return (
        <DefaultTemplate hero={<Hero text={title} />} activeNav={'EXPLORE'}>
            <VStack minH={'1000px'} w={'full'} align={'center'}>
                <VStack spacing={'40px'} w={'1460px'} align={'start'}>
                    <HStack w={'full'} justify={'space-between'}>
                        <HStack spacing={'16px'}>
                            <Button onClick={toggleFilters} px={'24px'} leftIcon={<MdFilterList />} colorScheme={'gray'} color={'white'}>Filters</Button>
                            <Button px={'24px'} leftIcon={<RiStackLine />} colorScheme={'gray'} color={'white'}>Sort by</Button>
                            <InputGroup variant={'filled'}>
                                <InputLeftElement pointerEvents={'none'}>
                                    <FiSearch />
                                </InputLeftElement>
                                <Input maxW={'380px'} _focus={{ outline: "none" }} type={'text'} placeholder={'Search'} value={keyword} onChange={e => setKeyword(e.target.value)} />
                            </InputGroup>
                        </HStack>
                        <HStack>
                            <Button onClick={() => setType('ALL')} {...type === 'ALL' ? active_button_style : {}} colorScheme={'gray'}>All</Button>
                            <Button onClick={() => setType('SINGLE')} {...type === 'SINGLE' ? active_button_style : {}} colorScheme={'gray'} color={'white'}>Single Titled</Button>
                            <Button onClick={() => setType('SERIES')} {...type === 'SERIES' ? active_button_style : {}} colorScheme={'gray'} color={'white'}>Series</Button>
                        </HStack>
                    </HStack>
                    <HStack justify={'space-between'} w={'full'} align={'start'}>
                        {has_filters &&
                            <ExploreFilter hasGenres={true} filters={filters} setFilters={setFilters} />
                        }

                        <Grid templateColumns={`repeat(${has_filters ? 5 : 7}, 1fr)`} gap={'30px'}>
                            {contents.map((item: any, index: number) =>
                                <GridItem key={index}>
                                    <ContentCard content={item} />
                                </GridItem>
                            )}
                        </Grid>
                    </HStack>
                </VStack>
            </VStack>
        </DefaultTemplate>
    )
}