import { Button, HStack, Img, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea, useDisclosure, VStack } from "@chakra-ui/react"
import { Link } from "../../../components/Link"
import { useContext, useState } from "react";
import { UserContext } from "../../../providers/UserProvider";
import { useScreenSize } from "../../../providers/ScreenSizeProvider";

interface SaveSocialInfoModalProps {
    onSave?: (basic_info: any) => void;
}

export const SaveSocialInfoModal = ({ onSave }: SaveSocialInfoModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { user } = useContext(UserContext)
    const [website_url, setWebsiteUrl] = useState<string>(user.website_url)
    const [instagram_url, setInstagramUrl] = useState<string>(() => {
        if (user.instagram_url) {
            return user.instagram_url
        } else {
            return `https://instagram.com/`
        }
    })
    const [twitter_url, setTwitterUrl] = useState<string>(() => {
        if (user.twitter_url) {
            return user.twitter_url
        } else {
            return `https://twitter.com/`
        }
    })
    const [facebook_url, setFacebookUrl] = useState<string>(() => {
        if (user.facebook_url) {
            return user.facebook_url
        } else {
            return `https://facebook.com/`
        }
    })
    const { isMobile } = useScreenSize()

    const save = () => {
        if (onSave) {
            onSave({ 
                website_url: sanitizeUrl(website_url), 
                instagram_url: sanitizeUrl(instagram_url), 
                twitter_url: sanitizeUrl(twitter_url), 
                facebook_url : sanitizeUrl(facebook_url)
            })
        }
        onClose()
    }

    const sanitizeUrl = (url: string) => {
        if (!/^https?:\/\//i.test(url)) {
            url = 'https://' + url;
        }
        return url
    }

    return (
        <>
            <Link onClick={onOpen}>Edit</Link>

            <Modal size={isMobile? 'sm': 'md'}  isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={'#1F1C2C'}>
                    <ModalHeader>Social Information</ModalHeader>
                    <ModalBody>
                        <VStack spacing={'16px'} w={'full'}>
                            <InputGroup>
                                <InputLeftElement pointerEvents='none'>
                                    <Img boxSize={'30px'} objectFit={'contain'} src={'/assets/images/icons/website.png'} />
                                </InputLeftElement>
                                <Input value={website_url} onChange={e => setWebsiteUrl(e.target.value)} py={'8px'} variant={'filled'} placeholder={'Enter website url'} />
                            </InputGroup>
                            <InputGroup>
                                <InputLeftElement pointerEvents='none'>
                                    <Img boxSize={'30px'} objectFit={'contain'} src={'/assets/images/icons/instagram.png'} />
                                </InputLeftElement>
                                <Input value={instagram_url} onChange={e => setInstagramUrl(e.target.value)} py={'8px'} variant={'filled'} placeholder={'Enter instagram url'} />
                            </InputGroup>
                            <InputGroup>
                                <InputLeftElement pointerEvents='none'>
                                    <Img boxSize={'30px'} objectFit={'contain'} src={'/assets/images/icons/twitter.png'} />
                                </InputLeftElement>
                                <Input value={twitter_url} onChange={e => setTwitterUrl(e.target.value)} py={'8px'} variant={'filled'} placeholder={'Enter twitter url'} />
                            </InputGroup>
                            <InputGroup>
                                <InputLeftElement pointerEvents='none'>
                                    <Img boxSize={'30px'} objectFit={'contain'} src={'/assets/images/icons/facebook.png'} />
                                </InputLeftElement>
                                <Input value={facebook_url} onChange={e => setFacebookUrl(e.target.value)} py={'8px'} variant={'filled'} placeholder={'Enter facebook url'} />
                            </InputGroup>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack w={'full'} spacing={'20px'}>
                            <Button w={'50%'} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button w={'50%'} bg={'green.400'} colorScheme='green' onClick={save}>
                                Save
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}