import { Storage } from '@ionic/storage';
import { RequestAddressProps } from './PaymentService';

interface UpdateBasicInfoReqProps {
    firstname?: string;
    lastname?: string;
    bio?: string;
    website_url?: string;
    facebook_url?: string;
    twitter_url?: string;
    instagram_url?: string;
    profile_picture_url?: string;
    cover_picture_url?: string;
}

interface UpdatePreferenceProps {
    genres: string[];
    age_range: string[];
}


const store = new Storage({
    name: process.env.REACT_APP_STORAGE_NAME
})

export const RequestRecover = async (email: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/accounts/recover/request`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email
        }),
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const ChangePassword = async (password: string) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/accounts/password/change`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            password
        }),
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        console.log(result)
        throw new Error(result.message)
    }
}

export const UpdateBasicInfo = async (basic_info: UpdateBasicInfoReqProps) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/accounts/basic-info/update`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(basic_info)
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const UpdateShippingAddress = async (address: RequestAddressProps)=> {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/accounts/shipping-address/update`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...address })
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const UpdatePreference = async (preference: UpdatePreferenceProps) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/accounts/preference/update`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(preference)
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}