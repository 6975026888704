import { Text, TextProps } from "@chakra-ui/react"
import { ReactNode } from "react"

interface LinkProps extends TextProps {
    children: ReactNode;
    isActive?: boolean;
}

export const Link = ({ children, isActive: is_active, ...rest }: LinkProps) => {
    return (
        <Text cursor={'pointer'} color={is_active ? 'green.400' : 'gray.200'} fontSize={'16px'} fontWeight={600} {...rest} >
            {children}
        </Text>
    )
}