import { BucketCannedACL, PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { Request, Response } from '@pollyjs/core';
import { findWhere } from 'underscore';
import { GetJson, SaveJson } from './MockHelper';

export const GenerateUploadUrl = async (req: Request, res: Response) => {
    try {
        const data: any = JSON.parse(req.body as string)
        const client = new S3Client({
            endpoint: "https://sfo3.digitaloceanspaces.com",
            forcePathStyle: false,
            region: "us-east-1",
            credentials: {
                accessKeyId: "DO0049NKPQ6TMDFKMXCY",
                secretAccessKey: "mi2NLxsfDRM3dI9kaVaB4R6ITEEEJGMJoL/qviIUU8w"
            }
        });

        const command = new PutObjectCommand({
            Bucket: "novatoons-dev",
            Key: `${data.asset_type}/${data.filename}`,
            Body: data.file,
            ACL: BucketCannedACL.public_read
        });
        const url = await getSignedUrl(client, command, { expiresIn: 3600 })
        const file_url = new window.URL(url)
        res.status(200).json({
            url: `https://${file_url.hostname}${file_url.pathname}`,
            request_url: url
        })
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const Save = async (req: Request, res: Response)=> {
    try {
        const asset = JSON.parse(req.body as string)
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        if (access_token) {
            let [users, assets] = await Promise.all([
                GetJson(`users`),
                GetJson(`assets`)
            ])
            const user = findWhere(users, {
                access_token
            })
            if (user) {
                if(!assets){
                    assets = []
                }
                asset.id = new Date().getTime()
                asset.owner = user
                assets.push({...asset})
                await SaveJson('assets', assets)
                res.status(200).json(asset)
            } else {
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }

    } catch (e: any) {
        res.status(500).json({ message: e.messasge })
    }
}