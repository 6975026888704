import { Box, Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { Country, State } from "@flowko/countries-cities-list"
import { ReactNode, useEffect, useState } from "react"
import { Select } from "../../../components/Select"

interface SaveShippingAddressModalProps {
    children: ReactNode;
    onSave?: (address: any) => void;
}

export const SaveShippingAddressModal = ({ onSave, children }: SaveShippingAddressModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [address_line, setAddressLine] = useState<string>('')
    const [country, setCountry] = useState<any>()
    const [province, setProvince] = useState<any>()
    const [city, setCity] = useState<string>('')
    const [zip_code, setZipCode] = useState<string>('')

    const [provinces, setProvinces] = useState<any[]>([])
    const [is_loading, setIsLoading] = useState<boolean>()

    useEffect(() => {
        if (country) {
            const states = State.getStatesOfCountry(country.value)
            setProvinces(() => states.map(item => ({ label: item.name, value: item.isoCode })))
        }
    }, [country])

    const save = () => {
        if(onSave){
            onSave({address_line, city, province, country, zip_code})
        }
        onClose()
    }

    return (
        <>
            <Box onClick={onOpen}>{children}</Box>
            <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={'#1F1C2C'} pb={'24px'}>
                    <ModalHeader>Add Shipping address</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={'24px'} align={'start'} w={'full'}>
                            <VStack spacing={'6px'} align={'start'} w={'full'}>
                                <Text color={'gray.300'} fontSize={'14px'}>Address Line</Text>
                                <Input value={address_line} onChange={e => setAddressLine(e.target.value)} placeholder={'Enter your address line'} variant={'filled'} />
                            </VStack>
                            <HStack spacing={'16px'} w={'full'}>
                                <VStack spacing={'6px'} align={'start'} w={'full'}>
                                    <Text color={'gray.300'} fontSize={'14px'}>Country</Text>
                                    <Select value={country} setValue={country => setCountry(country)} withSearch={true} placeholder={'Select country'} options={[...Country.getAllCountries().map((item: any) => ({ label: item.name, value: item.isoCode }))]} />
                                </VStack>
                                <VStack spacing={'6px'} align={'start'} w={'full'}>
                                    <Text color={'gray.300'} fontSize={'14px'}>State</Text>
                                    <Select value={province} setValue={province => setProvince(province)} placeholder={'Select state'} options={provinces} />
                                </VStack>
                            </HStack>
                            <HStack spacing={'16px'} w={'full'}>
                                <VStack spacing={'6px'} align={'start'} w={'full'}>
                                    <Text color={'gray.300'} fontSize={'14px'}>City</Text>
                                    <Input value={city} onChange={e => setCity(e.target.value)} placeholder={'Enter city'} variant={'filled'} />
                                </VStack>
                                <VStack spacing={'6px'} align={'start'} w={'full'}>
                                    <Text color={'gray.300'} fontSize={'14px'}>Zip Code</Text>
                                    <Input value={zip_code} onChange={e => setZipCode(e.target.value)} placeholder={'Enter zip code'} variant={'filled'} />
                                </VStack>
                            </HStack>
                            <HStack pt={'6px'} w={'full'} spacing={'16px'}>
                                <Button onClick={onClose} size={'md'} color={'green.400'} w={'50%'}>Cancel</Button>
                                <Button onClick={save} isLoading={is_loading} loadingText={`Saving...`} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'} size={'md'} w={'50%'}>
                                    Save
                                </Button>
                            </HStack>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}