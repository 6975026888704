import { BucketCannedACL, PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import axios from "axios"
const TEMP_API_BASE_URL = 'https://starfish-app-9lk9b.ondigitalocean.app'

declare var FB: any;

const AWS_CREDENTIALS = {
    key: 'DO0049NKPQ6TMDFKMXCY',
    secret: 'mi2NLxsfDRM3dI9kaVaB4R6ITEEEJGMJoL/qviIUU8w'
}

export const SaveJson = async (key: string, data: any) => {
    const bytes = new TextEncoder().encode(JSON.stringify(data));
    const blob = new Blob([bytes], {
        type: "application/json;charset=utf-8"
    });
    const client = new S3Client({
        endpoint: "https://sfo3.digitaloceanspaces.com",
        forcePathStyle: false,
        region: "us-east-1",
        credentials: {
            accessKeyId: AWS_CREDENTIALS.key,
            secretAccessKey: AWS_CREDENTIALS.secret
        }
    })

    const command = new PutObjectCommand({
        Bucket: "novatoons-dev",
        Key: `mock/${key}.json`,
        Body: blob,
        ACL: BucketCannedACL.public_read
    })

    const url = await getSignedUrl(client, command, { expiresIn: 3600 })
    await axios(url, {
        method: "PUT",
        headers: {
            "x-amz-acl": "public-read"
        },
        data: blob
    })
}

export const GetJson = async (key: string) => {
    try {
        const response = await axios.get(`https://novatoons-dev.sfo3.digitaloceanspaces.com/mock/${key}.json?ct=${new Date().getTime()}`)
        if (response.status === 200) {
            let data = response.data
            if (typeof (data) === 'string') {
                data = JSON.parse(response.data)
            }
            return data
        } else {
            throw new Error(`GetJson Request Error`)
        }
    } catch (e: any) {
        return []
    }
}

export const GenerateToken = (len: number) => {
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var token = '';
    for (var i = 0; i < len; i++) {
        token += chars[Math.floor(Math.random() * chars.length)];
    }
    return token;
}

export const GetFacebookUser = (access_token: string) => {
    return new Promise((resolve: any, reject: any) => {
        FB.api('/me?fields=email,first_name,last_name', function (response: any) {
            resolve({
                email: response.email,
                firstname: response.first_name,
                lastname: response.last_name
            })
        });
    })
}

export const GetGoogleUser = async (access_token: string) => {
    const result = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${access_token}`)
    const data = await result.json()
    const { email, given_name, family_name } = data
    return {
        email,
        firstname: given_name,
        lastname: family_name
    }
}

export const SendEmail = async (user: any, code: string) => {
    const data = {
        ...user,
        code
    }
    const result = await fetch(
        `${TEMP_API_BASE_URL}/api/send-email`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    ).then((r) => r.json())
    return result
}