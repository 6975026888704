import { send } from '@emailjs/browser'
import { Request, Response } from '@pollyjs/core'
import { findIndex, findWhere } from 'underscore'
import { GenerateToken, GetJson, SaveJson, SendEmail } from './MockHelper'

export const RequestRecover = async (req: Request, res: Response) => {
    try {
        const { email } = JSON.parse(req.body as string)
        const users = await GetJson(`users`)
        const index = findIndex(users, item => item.email.toLowerCase() === email.toLowerCase())
        const verification_code = Math.floor(1000 + Math.random() * 9000)
        const verification_token = GenerateToken(48)
        if (index > -1) {
            const user = users[index]
            users[index].verification_code = verification_code
            users[index].verification_token = verification_token
            await SaveJson(`users`, users)
            await SendEmail(user, verification_code.toString())
            // await send(process.env.REACT_APP_EMAILJS_SERVICE_ID as string, process.env.REACT_APP_EMAILJS_REGISTER_TEMPLATE_ID as string, {
            //     name: `${user.firstname} ${user.lastname}`,
            //     code: verification_code,
            //     recipient: user.email
            // })
            res.status(200).json({ message: `We just sent the email recovery code to your email`, token: verification_token })
        }else{
            throw new Error(`Email entered is incorrect / doesn't exists`)
        }
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const ChangePassword = async (req: Request, res: Response)=> {
    try {
        const { password } = JSON.parse(req.body as string)
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        console.log(access_token)
        if (access_token) {
            const users = await GetJson(`users`)
            const index = findIndex(users, item => item.access_token === access_token)
            console.log(index, users)
            if(index > -1){
                users[index].password = password
                await SaveJson(`users`, users)
                res.status(200).json({ message: `You successfully changed your password`})
            }else{
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {
        console.log(e, 'error')
        res.status(500).json({ message: e.messasge })
    }
}

export const UpdateBasicInfo = async (req: Request, res: Response)=> {
    try {
        const basic_info = JSON.parse(req.body as string)
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        if (access_token) {
            const users = await GetJson(`users`)
            const index = findIndex(users, item => item.access_token === access_token)
            if(index > -1){
                const user = {
                    ...users[index],
                    ...basic_info
                }
                users[index] = {...user}
                await SaveJson(`users`, users)
                res.status(200).json({ ...user })
            }else{
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {
        console.log(e, 'error')
        res.status(500).json({ message: e.messasge })
    }
}
export const UpdatePreference = async (req: Request, res: Response)=> {
    try {
        const preference = JSON.parse(req.body as string)
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        if (access_token) {
            const users = await GetJson(`users`)
            const index = findIndex(users, item => item.access_token === access_token)
            if(index > -1){
                const user = {
                    ...users[index],
                    preference
                }
                users[index] = {...user}
                await SaveJson(`users`, users)
                res.status(200).json({ ...user })
            }else{
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {
        console.log(e, 'error')
        res.status(500).json({ message: e.messasge })
    }
}

export const UpdateShippingAddress = async (req: Request, res: Response)=> {
    try {
        const address = JSON.parse(req.body as string)
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        if (access_token) {
            const users = await GetJson(`users`)
            const index = findIndex(users, item => item.access_token === access_token)
            if(index > -1){
                const user = {
                    ...users[index],
                    address
                }
                users[index] = {...user}
                await SaveJson(`users`, users)
                res.status(200).json({ ...user })
            }else{
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {
        console.log(e, 'error')
        res.status(500).json({ message: e.messasge })
    }
}