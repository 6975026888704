import { Button, HStack, StackProps, Text, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { GetGenreListing } from "../../../services/ContentService";

interface SelectGenreCardProps extends StackProps {
    setGenres?: (genres: any[]) => void;
}

export const SelectGenreCard = ({ setGenres: setSelectedGenres }: SelectGenreCardProps) => {
    const [genres, setGenres] = useState<any[]>([])

    useEffect(() => {
        (async () => {
            const result = await GetGenreListing()
            setGenres(() => result)
        })();
    }, [])

    const toggle = (genre: any) => {
        setGenres(() => [...genres.map(item => ({ ...item, is_selected: genre.code === item.code ? !item.is_selected : item.is_selected }))])
    }

    const save = ()=> {
        if(setSelectedGenres){
            setSelectedGenres([...genres.filter(item=> item.is_selected)])
        }
    }
    return (
        <VStack px={'20px'} py={'24px'} borderRadius={'8px'} w={'500px'} bg={'#262333'}>
            <VStack spacing={'32px'} w={'full'}>
                <VStack w={'full'} spacing={'4px'}>
                    <Text fontSize={'24px'} fontWeight={700} color={'gray.300'}>
                        What type of genres are you most interested in?
                    </Text>
                    <Text fontSize={'14px'} color={'gray.400'}>
                        Your choices will help us recommend personalized content tailored to your unique tastes and interests.
                    </Text>
                </VStack>
                <HStack spacing={'16px'} w={'full'} align={'start'} flexWrap={'wrap'}>
                    {genres.map((item: any, index: number) =>
                        <Button onClick={() => toggle(item)} borderColor={'gray.300'} borderWidth={item.is_selected ? '1px' : 0} key={index}>{item.name}</Button>
                    )}
                </HStack>
                <Button onClick={save} bg={'green.400'} w={'full'} colorScheme={'green'}>Proceed</Button>
            </VStack>
        </VStack>
    )
}