import { Button, HStack, Img, Input, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast, VStack } from "@chakra-ui/react"
import { Login, LoginWithFacebook, LoginWithGoogle } from "../services/AuthService";
import { Storage } from '@ionic/storage'
import { useNavigate } from "react-router-dom";
import { UserContext } from "../providers/UserProvider";
import { useContext, useState } from "react";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { useGoogleLogin } from "@react-oauth/google";
import { polly } from "../mocks/MockServer";
import { useScreenSize } from "../providers/ScreenSizeProvider";

const store = new Storage({
    name: process.env.REACT_APP_STORAGE_NAME
})

declare var FB: any;

interface LoginModalProps {
    disclosure: any;
}

export const LoginModal = ({ disclosure }: LoginModalProps) => {
    const { isOpen, onClose } = disclosure
    const navigate = useNavigate()
    const { setUser } = useContext(UserContext)
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [is_logging_in, setIsLoggingIn] = useState<boolean>()
    const toast = useToast()
    const { isMobile } = useScreenSize()

    const login = async () => {
        setIsLoggingIn(() => true)
        const storage = await store.create();
        try {
            const user = await Login(email, password)
            const { is_verified, access_token, verification_token } = user
            if (is_verified) {
                await storage.set(`access_token`, access_token)
                setUser(() => user)
                window.location.href = '/'
            } else {
                navigate(`/register/verify/${verification_token}`)
            }
        } catch (e: any) {
            toast({
                status: `error`,
                description: e.message,
                duration: 3000,
                position: 'bottom-right'
            })
        }
        setIsLoggingIn(() => false)
    }

    const isValid = () => {
        return email && password
    }

    const loginWithFacebook = async () => {
        const storage = await store.create();
        const access_token = await getFacebookToken()
        setIsLoggingIn(() => true)
        const user = await LoginWithFacebook(access_token as string)
        await storage.set(`access_token`, user.access_token)
        setUser(() => user)
        window.location.href = '/'
    }

    const getFacebookToken = () => {
        polly.pause()
        return new Promise((resolve: any, reject: any) => {
            FB.login((response: any) => {
                const { authResponse } = response
                const { accessToken } = authResponse
                resolve(accessToken)
                polly.play()
            }, { scope: 'email' })
        })
    }

    const loginWithGoogle = useGoogleLogin({
        onSuccess: async (response: any) => {
            const storage = await store.create();
            const { access_token } = response
            const user = await LoginWithGoogle(access_token)
            await storage.set(`access_token`, user.access_token)
            setUser(() => user)
            window.location.href = '/'
        }
    })

    return (
        <Modal size={isMobile ? 'full' : 'lg'} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <ModalContent py={'24px'} bg={'#262333'}>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={'32px'} align={'start'} w={'full'}>
                        <VStack spacing={'20px'} w={'full'} align={'start'}>
                            <Img h={'50px'} cursor={'pointer'} onClick={() => navigate(`/`)} src={'/assets/images/logo-vertical.png'} />
                            <Text fontWeight={600} fontSize={'2xl'}>Welcome back to Novatoons</Text>
                        </VStack>
                        <VStack w={'full'} spacing={'16px'}>
                            <Button onClick={() => loginWithGoogle()} leftIcon={<FaGoogle />} w={'full'}>
                                Continue with Google
                            </Button>
                            <Button onClick={() => loginWithFacebook()} leftIcon={<FaFacebook />} w={'full'}>
                                Continue with Facebook
                            </Button>
                        </VStack>
                        <VStack w={'full'} spacing={'16px'}>
                            <Input value={email} onChange={e => setEmail(e.target.value)} variant={'filled'} fontSize={'sm'} size={'lg'} placeholder={'Email Address'} />
                            <Input value={password} onChange={e => setPassword(e.target.value)} variant={'filled'} fontSize={'sm'} size={'lg'} placeholder={'Password'} type={'password'} />
                            <Button isLoading={is_logging_in} loadingText={`Logging in...`} isDisabled={!isValid()} mt={'8px'} onClick={login} _hover={{ bg: 'green.300' }} w={'full'} bg={'green.400'} color={'black'}>
                                Proceed
                            </Button>
                            <VStack w={'full'} spacing={'10px'}>
                                <HStack align={'center'} justify={'center'} w={'full'}>
                                    <Text color={'gray.300'} fontSize={'sm'}>Forgot your password?</Text>
                                    <Link onClick={() => navigate(`/recover`)} color={'gray.300'} fontWeight={600}>Reset</Link>
                                </HStack>
                                <HStack align={'center'} justify={'center'} w={'full'}>
                                    <Text color={'gray.300'} fontSize={'sm'}>Don't have an account?</Text>
                                    <Link onClick={() => navigate(`/register`)} color={'gray.300'} fontWeight={600}>Sign up</Link>
                                </HStack>
                            </VStack>
                        </VStack>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}