import { Button, Center, HStack, IconButton, Input, Stack, StackProps, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { FiBookmark, FiChevronRight, FiDollarSign, FiEye, FiGift, FiX } from "react-icons/fi"
import { useNavigate, useParams } from "react-router-dom"
import { findWhere } from "underscore"
import { Select as NovaSelect } from '../../../components/Select'
import { ContentFileProps, ContentProps, GetDraft, GetGenreListing, GetTagListing, Save } from "../../../services/ContentService"
import { MainTemplate } from "../../../templates/MainTemplate"
import { SaveProgressBar } from "../components/SaveProgressBar"
import { UploadFileBox } from "../components/UploadFileBox"
import { useScreenSize } from "../../../providers/ScreenSizeProvider"


interface SaveContentPageProps {

}

const pricing_style: StackProps = {
    spacing: '4px',
    borderRadius: '8px',
    bg: '#2F2C3B',
    w: '50%',
    h: '123px',
    justify: 'center',
    cursor: 'pointer'
}

const active_pricing_style = {
    ...pricing_style,
    bg: '#353444',
    borderColor: '#98A2B3',
    borderWidth: '1px'
}

export const SaveContentPage = ({ }: SaveContentPageProps) => {
    const [is_saving, setIsSaving] = useState<boolean>()
    const [upload_file, setUploadFile] = useState<ContentFileProps>()
    const toast = useToast()
    const { isMobile } = useScreenSize()
    const [content, setContent] = useState<ContentProps>({
        type: '',
        thumbnail_url: '',
        title: '',
        description: '',
        genres: [],
        tags: [],
        is_paid: false
    })
    const [genres, setGenres] = useState<{ label: string, value: string }[]>([])
    const [tags, setTags] = useState<{ label: string, value: string }[]>([])
    const upload_categories = [
        {
            label: 'Single comic',
            value: 'SINGLE'
        },
        {
            label: 'Comic series',
            value: 'SERIES'
        }
    ]
    const navigate = useNavigate()
    const { id } = useParams()

    useEffect(() => {
        if (id) {
            (async () => {
                try {
                    const result = await GetDraft(parseInt(id))
                    setContent(() => result)
                } catch (e) {
                    console.log(e, 'failed to load content')
                }
            })();
        }
    }, [id])

    useEffect(() => {
        (async () => {
            const result = await GetGenreListing()
            setGenres(() => result.map((item: any) => ({ label: item.name, value: item.code })))
        })();
        (async () => {
            const result = await GetTagListing()
            setTags(() => result.map((item: any) => ({ label: item.name, value: item.code })))
        })();
    }, [])

    useEffect(() => {
        if (upload_file) {
            setContent(() => ({ ...content, thumbnail_url: upload_file.url }))
        }
    }, [upload_file])

    const getFee = () => {
        if (content.is_paid && content.price) {
            const value = (content.price * 20) / 100
            return Math.round((value + Number.EPSILON) * 100) / 100
        } else {
            return 0
        }
    }

    const getNetAmount = () => {
        let amount = 0
        if (content.price) {
            amount = content.price - getFee()
        }
        return Math.round((amount + Number.EPSILON) * 100) / 100
    }

    const isValid = () => {
        return (content.title && content.thumbnail_url && content.type) ? true : false
    }

    const save = async () => {
        setIsSaving(() => true)
        try {
            const { id } = await Save(content)
            navigate(`/contents/upload/${id}`)
        } catch (e) {
            console.log(e, 'error')
        }
        setIsSaving(() => false)
    }

    const getGenreLabel = (code: string) => {
        const genre = findWhere(genres, { value: code })
        if (genre) {
            return genre.label
        } else {
            return code.replaceAll('_', ' ').toLowerCase()
        }
    }

    const onSaveDraft = async () => {
        setIsSaving(() => true)
        try {
            await Save(content)
            toast({
                description: `Changes saved as draft`,
                duration: 2000,
                status: 'success'
            })
        } catch (e) {
            console.log(e, 'error')
        }
        setIsSaving(() => false)
    }

    const onPreview = async () => {
        setIsSaving(() => true)
        try {
            const { id } = await Save(content)
            navigate(`/contents/${id}`)
        } catch (e) {
            console.log(e, 'error')
        }
        setIsSaving(() => false)
    }

    return (
        <MainTemplate>
            <VStack w={'full'}>
                <VStack w={'full'} maxW={'800px'} spacing={'32px'}>
                    <SaveProgressBar title={'Content Information'} value={1}>
                        <HStack maxW={isMobile ? 'inherit' : '260px'} w={'full'} spacing={'16px'}>
                            <Button flexGrow={1} isDisabled={!isValid()} onClick={onPreview} px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'} leftIcon={<FiEye />}>
                                Preview
                            </Button>
                            <Button flexGrow={1} isDisabled={!isValid()} onClick={onSaveDraft} variant={'outline'} px={'24px'} leftIcon={<FiBookmark />}>
                                Save
                            </Button>
                        </HStack>
                    </SaveProgressBar>
                    <Stack direction={isMobile ? 'column-reverse' : 'row'}
                        align={'start'}
                        w={'full'}
                        minH={'600px'}
                        bg={'#262333'}
                        borderRadius={'16px'}
                        p={isMobile ? '24px' : '32px'}
                        spacing={'20px'}>
                        <VStack w={'full'} spacing={'24px'} align={'start'}>
                            <VStack w={'full'} align={'start'}>
                                <Text>Upload category</Text>
                                <NovaSelect value={findWhere(upload_categories, { value: content.type })}
                                    setValue={value => setContent({ ...content, type: value.value })}
                                    offset={[175, 10]}
                                    options={upload_categories}
                                    placeholder={'Choose an upload category'}
                                    subPlaceholder={'Select category'} />
                            </VStack>
                            <VStack w={'full'} align={'start'}>
                                <Text>Title</Text>
                                <Input value={content.title} onChange={e => setContent({ ...content, title: e.target.value })} _placeholder={{ color: 'gray.400' }} variant={'filled'} placeholder={'Enter comic title'} />
                            </VStack>
                            <VStack w={'full'} align={'start'}>
                                <Text>Brief description</Text>
                                <Textarea maxLength={300} value={content.description} onChange={e => setContent({ ...content, description: e.target.value })} _placeholder={{ color: 'gray.400' }} rows={6} variant={'filled'} placeholder={'Enter brief description'} resize={'none'} />
                                <Text fontSize={'14px'} color={'gray.400'}>{content.description.length} / 300 characters max</Text>
                            </VStack>
                            <VStack w={'full'} align={'start'}>
                                <Text>Genre</Text>
                                <NovaSelect
                                    setValue={value => setContent({ ...content, genres: content.genres.indexOf(value.value) === -1 ? [...content.genres, value.value] : content.genres })}
                                    offset={[175, 10]}
                                    options={genres}
                                    placeholder={'Select genre'} />
                                <HStack w={'full'} flexWrap={'wrap'}>
                                    {content.genres.map((item: string, index: number) =>
                                        <HStack gap={'12px'} padding={'8px'} borderWidth={'1px'} borderStyle={'dashed'} borderRadius={'4px'} key={index}>
                                            <Text textTransform={'capitalize'}>{getGenreLabel(item)}</Text>
                                            <Center padding={'2px'} cursor={'pointer'} onClick={() => setContent({ ...content, genres: content.genres.filter((item, _index) => index !== _index) })}>
                                                <FiX />
                                            </Center>
                                        </HStack>
                                    )}
                                </HStack>
                            </VStack>
                            <VStack w={'full'} align={'start'}>
                                <Text>Tags</Text>
                                <NovaSelect
                                    withSearch={true}
                                    setValue={value => setContent({ ...content, tags: content.tags.indexOf(value.value) === -1 ? [...content.tags, value.value] : content.tags })}
                                    offset={[175, 10]}
                                    options={tags}
                                    placeholder={'Select tags'} />
                                <HStack w={'full'} flexWrap={'wrap'}>
                                    {content.tags.map((item: string, index: number) =>
                                        <HStack gap={'12px'} padding={'8px'} borderWidth={'1px'} borderStyle={'dashed'} borderRadius={'4px'} key={index}>
                                            <Text textTransform={'capitalize'}>{getGenreLabel(item)}</Text>
                                            <Center padding={'2px'} cursor={'pointer'} onClick={() => setContent({ ...content, tags: content.tags.filter((item, _index) => index !== _index) })}>
                                                <FiX />
                                            </Center>

                                        </HStack>
                                    )}
                                </HStack>
                            </VStack>
                            <VStack w={'full'} align={'start'}>
                                <Text>Pricing & monetization</Text>
                                <HStack w={'full'} spacing={'24px'}>
                                    <VStack onClick={() => setContent({ ...content, is_paid: false })} {...!content.is_paid ? active_pricing_style : pricing_style}>
                                        <Center w={'50px'} h={'50px'} bg={'#3D3C4B'} borderRadius={'50%'}>
                                            <FiGift size={'24px'} />
                                        </Center>
                                        <Text fontSize={'14px'}>Free</Text>
                                    </VStack>
                                    <VStack onClick={() => setContent({ ...content, is_paid: true })} {...content.is_paid ? active_pricing_style : pricing_style}>
                                        <Center w={'50px'} h={'50px'} bg={'#3D3C4B'} borderRadius={'50%'}>
                                            <FiDollarSign size={'24px'} />
                                        </Center>
                                        <Text fontSize={'14px'}>Paid</Text>
                                    </VStack>
                                </HStack>
                            </VStack>
                            {content.is_paid &&
                                <VStack w={'full'} align={'start'}>
                                    <Input type={'number'} value={content.price} onChange={e => setContent({ ...content, price: parseFloat(e.target.value) })} _placeholder={{ color: 'gray.400' }} variant={'filled'} placeholder={'Enter amount'} />
                                    <VStack w={'full'} spacing={0} align={'start'}>
                                        <Text color={'gray.300'} fontSize={'14px'}>20% Novatoons Service fee:  <Text color={'gray.400'} as={'span'}>$ {getFee()}</Text></Text>
                                        <Text color={'gray.300'} fontSize={'14px'}>You will receive:  <Text color={'gray.400'} as={'span'}>$ {getNetAmount()}</Text></Text>
                                    </VStack>
                                </VStack>
                            }
                        </VStack>
                        <VStack align={'start'} w={'full'}>
                            <Text>Upload Thumbnail</Text>
                            <UploadFileBox w={isMobile ? 'full' : '254px'} h={'314px'} file={content.thumbnail_url ? { url: content.thumbnail_url } : undefined} setFile={(file: ContentFileProps) => setUploadFile(file)} />
                        </VStack>
                    </Stack>
                    {isMobile &&
                        <HStack justify={'end'} w={'full'} spacing={'16px'}>
                            <IconButton
                                aria-label={'Save'}
                                isLoading={is_saving}
                                onClick={save} isDisabled={!isValid()}
                                px={'24px'}
                                _hover={{ bg: 'green.300' }}
                                bg={'green.400'}
                                color={'black'}
                                icon={<FiChevronRight />} />
                        </HStack>
                        ||
                        <HStack justify={'end'} w={'full'} spacing={'16px'}>
                            <Button isLoading={is_saving}
                                loadingText={`Saving...`}
                                onClick={save} isDisabled={!isValid()}
                                px={'24px'}
                                _hover={{ bg: 'green.300' }}
                                bg={'green.400'}
                                color={'black'}
                                rightIcon={<FiChevronRight />}>
                                Proceed
                            </Button>
                        </HStack>
                    }
                </VStack>
            </VStack>
        </MainTemplate>

    )
}