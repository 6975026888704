import { Button, Text, VStack } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

export const EmptyUpload = () => {
    const navigate = useNavigate()
    return (
        <VStack spacing={'16px'} w={'320px'} align={'center'}>
            <VStack spacing={'4px'}>
                <Text fontSize={'14px'} fontWeight={600} textAlign={'center'}>No comics uploaded</Text>
                <Text color={'gray.400'} fontSize={'14px'} textAlign={'center'}>
                    You're yet to upload a comic. click on the "Upload comic" to begin the process.
                </Text>
            </VStack>
            <Button onClick={() => navigate(`/contents/save`)} px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'}>Upload Comic</Button>
        </VStack>
    )
}