import { Img, Text, VStack } from "@chakra-ui/react"
import { useContext } from "react"
import { UserContext } from "./providers/UserProvider"
import { HomePage } from "./modules/public/pages/HomePage"
import { SavePreferencePage } from "./modules/account/pages/SavePreferencePage"

export const Bootstrap = ()=> {
    const { user, setUser, isReady }= useContext(UserContext)
    return (
        <>
            { !isReady &&
                <VStack w={window.innerWidth} h={window.innerHeight} align={'center'} justify={'center'}>
                    <Img src={'/assets/images/logo-vertical.png'} w={'120px'} />
                </VStack>
            }
            { (isReady && user && user.preference) &&
                <HomePage />
            }
            { (isReady && user && !user.preference) &&
                <SavePreferencePage />
            }
            { (isReady && !user) &&
                <HomePage />
            }
        </>
    )
}