import { VStack } from "@chakra-ui/react"
import { ReactNode } from "react"
import { Header } from "../components/Header"
import { PageContent } from "../components/PageContent"

interface DefaultTemplateProps {
    children: ReactNode;
    activeNav?: 'HOME' | 'EXPLORE' | 'GENRE' | 'MERCHANDISE' | 'ABOUT_US' | 'HELP_CENTER';
    hero?: ReactNode;
}

export const DefaultTemplate = ({ children, hero, activeNav = 'HOME' }: DefaultTemplateProps) => {
    return (
        <VStack w={'full'} spacing={0}>
            <Header activeNav={activeNav} isFloating={true} />
            {hero}
            <PageContent>
                {children}
            </PageContent>
        </VStack>
    )
}