import { Box, BoxProps, Button, Img, ImgProps, StackProps, Text, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { useScreenSize } from "../../../providers/ScreenSizeProvider";

interface HeroProps {
    text: string;
    children?: ReactNode;
    bgImage?: string;
    height?: string;
}

const container_style: BoxProps = {
    w: 'full',
    overflow: 'hidden'
}

const content_style: StackProps = {
    w: 'full',
    pos: 'absolute',
    zIndex: 1,
    justify: 'center',
    spacing: '24px'
}

const image_mobile_style: ImgProps = {
    h: 'full',
    objectFit: 'cover'
}


export const Hero = ({ text, children, bgImage = '/assets/images/backgrounds/explore-hero.png', height = '420px' }: HeroProps) => {
    const { isMobile } = useScreenSize()
    return (
        <VStack spacing={0} w={'full'}>
            <Box h={height} {...container_style}>
                <VStack h={height} {...content_style}>
                    <Text lineHeight={1.2}
                        textAlign={'center'}
                        w={!isMobile ? '651px' : '90%'}
                        fontSize={!isMobile ? '6xl' : '4xl'}
                        fontWeight={600}>
                        {text}
                    </Text>
                    {children}
                </VStack>
                <Img {...!isMobile ? {} : image_mobile_style} w={'full'} src={bgImage} />
            </Box>
        </VStack>
    )
}