import { Button, VStack } from "@chakra-ui/react"
import { Hero } from "./Hero"
import { GenreCardGrid } from "./GenreCardGrid"

export const HomeHero = () => {
    return (
        <VStack spacing={0} w={'full'}>
            <Hero text={'Discover Comics, Art And Community'} bgImage={'/assets/images/backgrounds/home-hero.png'} height={'680px'}>
                <Button size={'lg'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'}>Get Started</Button>
            </Hero>
            <GenreCardGrid />
        </VStack>
        
    )
}