import { Request, Response } from '@pollyjs/core'
import { RequestAddressProps } from '../services/PaymentService'
import { GetJson, SaveJson } from './MockHelper'
import { findIndex, findWhere } from 'underscore'
import { send } from '@emailjs/browser'

// const TEMP_API_BASE_URL = 'http://localhost:8080'
const TEMP_API_BASE_URL = 'https://starfish-app-9lk9b.ondigitalocean.app'

export const GetCreditCardListing = async (req: Request, res: Response) => {
    try{
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        if (access_token) {
            const users = await GetJson(`users`)
            const user = findWhere(users, {
                access_token
            })
            if (user) {
                if(user.stripe_customer_id){
                    const response = await fetch(
                        `${TEMP_API_BASE_URL}/api/get-payment-methods?stripe_customer_id=${user.stripe_customer_id}`,
                        {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        }
                    ).then((r) => r.json());
                    const result = response.map((item: any)=> ({...item.card, id: item.id, billing_details: item.billing_details}))
                    res.status(200).json([...result])
                }else{
                    res.status(200).json([])
                }
            } else {
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    }catch(e){
        res.status(200).json([])
    }
    
}

export const InitializeAccount = async (req: Request, res: Response) => {
    try {
        const address = JSON.parse(req.body as string) as RequestAddressProps
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        if (access_token) {
            const users = await GetJson(`users`)
            const index = findIndex(users, item => item.access_token === access_token)
            if (index > -1) {
                const user = users[index]
                const { password, ..._user } = user

                const data = { ...address, email: _user.email, name: _user.firstname+' '+_user.lastname }
                const { stripe_customer_id } = await fetch(
                    `${TEMP_API_BASE_URL}/api/create-customer`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    }
                ).then((r) => r.json());
                users[index].stripe_customer_id = stripe_customer_id
                users[index].shipping_address = {...address}
                await SaveJson(`users`, users)
                res.status(200).json({ ..._user, stripe_customer_id })
            } else {
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {

    }
}

export const AddPaymentMethod = async (req: Request, res: Response) => {
    try {
        const { stripe_payment_method_id } = JSON.parse(req.body as string)
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        if (access_token) {
            const users = await GetJson(`users`)
            const index = findIndex(users, item => item.access_token === access_token)
            if (index > -1) {
                const user = users[index]
                const { password, stripe_customer_id, ..._user } = user

                await fetch(
                    `${TEMP_API_BASE_URL}/api/add-customer-payment-menthod`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ stripe_payment_method_id, stripe_customer_id }),
                    }
                ).then((r) => r.json());
                res.status(200).json({ ...user })
            } else {
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {

    }
}

export const Initialize = async (req: Request, res: Response) => {
    try {
        const { amount } = JSON.parse(req.body as string)
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        if (access_token) {
            const users = await GetJson(`users`)
            const index = findIndex(users, item => item.access_token === access_token)
            if (index > -1) {
                const user = users[index]
                const { stripe_customer_id } = user

                const payment_intent = await fetch(
                    `${TEMP_API_BASE_URL}/api/create-payment-intent`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            stripe_customer_id,
                            amount
                        }),
                    }
                ).then((r) => r.json());
                res.status(200).json({ ...payment_intent })
            } else {
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const Confirm = async (req: Request, res: Response) => {
    try {
        const { content_id, sub_content_id, payment_method_id, payment_intent_id } = JSON.parse(req.body as string)
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        if (access_token) {
            const [users, contents, transactions, user_contents] = await Promise.all([
                GetJson(`users`),
                GetJson(`contents`),
                GetJson(`transactions`),
                GetJson(`user_contents`),
            ])
            const content = findWhere(contents, { id: parseInt(content_id)})
            const content_earning = content.price * 0.8
            const index = findIndex(users, item => item.access_token === access_token)
            if (index > -1) {
                //  confirm payment
                const user = users[index]
                await fetch(
                    `${TEMP_API_BASE_URL}/api/pay`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            payment_method_id,
                            payment_intent_id
                        }),
                    }
                ).then((r) => r.json());

                //  send receipt
                await send(process.env.REACT_APP_EMAILJS_SERVICE_ID as string, process.env.REACT_APP_EMAILJS_PURCHASE_TEMPLATE_ID as string, {
                    content_name: content.title,
                    name: `${user.firstname} ${user.lastname}`,
                    amount: content.price,
                    content_id: content.id,
                    email: user.email,
                })

                //  update owner earnings
                const _index = findIndex(users, (item: any)=> item.email === content.owner.email)
                console.log(_index, '_index')
                if(_index > -1){
                    let earnings = users[_index].earnings
                    if(!earnings){
                        earnings = 0
                    }

                    earnings += content_earning
                    users[_index].earnings = earnings
                    await SaveJson(`users`, users)
                }

                //  save transaction logs
                transactions.push({
                    type: 'COMPIC_PURCHASE',
                    user: users[_index],
                    amount: content_earning,
                    status: 'SUCCESS',
                    note: ``,
                    date: new Date()
                })

                await SaveJson(`transactions`, transactions)

                //  save user contents
                user_contents.push({
                    type: 'PURCHASE',
                    user_id: user.id,
                    content_id, 
                    sub_content_id
                })
                await SaveJson(`user_contents`, user_contents)

                res.status(200).json({ is_success: true })
            } else {
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {
        console.log(e)
        res.status(500).json({ message: e.message })
    }
}

export const InitializePayoutAccount = async (req: Request, res: Response)=> {
    try {
        const { country_code } = JSON.parse(req.body as string)
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        if (access_token) {
            const users = await GetJson(`users`)
            const index = findIndex(users, item => item.access_token === access_token)
            if (index > -1) {
                const user = users[index]
                const { password, ..._user } = user

                const { stripe_payout_account_id, stripe_payout_account_onboarding_link } = await fetch(
                    `${TEMP_API_BASE_URL}/api/create-payout-account`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email: _user.email, country_code}),
                    }
                ).then((r) => r.json());
                
                const stripe_payout_account = {
                    id: stripe_payout_account_id, 
                    onboarding_link: stripe_payout_account_onboarding_link
                }
                users[index].stripe_payout_account = {...stripe_payout_account}
                await SaveJson(`users`, users)
                res.status(200).json({ ..._user, stripe_payout_account: stripe_payout_account})
            } else {
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const RequestPayout = async (req: Request, res: Response)=> {
    try {
        const { amount } = JSON.parse(req.body as string)
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        if (access_token) {
            const [users, transactions] = await Promise.all([
                GetJson(`users`),
                GetJson(`transactions`)
            ])
            const index = findIndex(users, item => item.access_token === access_token)
            if (index > -1) {
                const user = users[index]
                const { password, ..._user } = user

                await fetch(
                    `${TEMP_API_BASE_URL}/api/request-payout`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ amount, stripe_payout_account_id: _user.stripe_payout_account.id}),
                    }
                ).then((r) => r.json());
                
                const deducted_earnings = _user.earnings - amount
                users[index].earnings = deducted_earnings
                await SaveJson(`users`, users)

                transactions.push({
                    type: 'PAYOUT',
                    user: _user,
                    amount: amount,
                    status: 'PENDING',
                    note: `The amount you requested will reflect in your bank account within 5-7 business days`,
                    date: new Date()
                })
                await SaveJson(`transactions`, transactions)
                
                res.status(200).json({ ..._user, earnings: deducted_earnings })
            } else {
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}