import { Box, HStack, IconButton, Stack, useDisclosure } from "@chakra-ui/react";
import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { LazyLoadImage, trackWindowScroll } from "react-lazy-load-image-component";
import { useOnScreen } from "../../../providers/ScreenProvider";
import { LoginModal } from "../../../components/LoginModal";

interface ContentReaderProps {
    pages: string[];
    scrollPosition: any;
    layout?: 'HORIZONTAL' | 'VERTICAL' | 'SINGLE_PAGING' | 'DOUBLE_PAGING' | string;
}

const ContentReader = ({ pages, layout = 'VERTICAL', scrollPosition }: ContentReaderProps) => {
    const image_container = useRef<any>()
    const end_box_ref = useRef<any>()
    const is_visible = useOnScreen(end_box_ref)
    const login_disclosure = useDisclosure()
    const [counter, setCounter] = useState<number>(0)

    useEffect(()=> {
        if(counter > 1){
            login_disclosure.onOpen()
        }
    }, [counter])

    useEffect(()=> {
        if(is_visible){
            setCounter(()=> counter + 1)
        }
    }, [is_visible])

    const next = () => {
        image_container.current.scrollLeft += 1340
    }

    const prev = () => {
        image_container.current.scrollLeft -= 1340
    }

    return (
        <Box pos={'relative'}>
            {['DOUBLE_PAGING', 'SINGLE_PAGING'].indexOf(layout) > -1 &&
                <HStack left={'-1%'} top={'300px'} pos={'absolute'} w={'102%'} justify={'space-between'}>
                    <IconButton onClick={prev} colorScheme="gray" aria-label={'Prev'} icon={<FiChevronLeft />} />
                    <IconButton onClick={next} aria-label={'Next'} icon={<FiChevronRight />} />
                </HStack>
            }

            <Box ref={image_container} overflowX={layout === 'HORIZONTAL' ? 'scroll' : 'hidden'} w={'full'} maxW={'1340px'}>
                <Stack minH={'400px'} direction={['HORIZONTAL', 'DOUBLE_PAGING', 'SINGLE_PAGING'].indexOf(layout) > -1 ? 'row' : 'column'} spacing={0}>
                    {pages.map((item: string, index: number) =>
                        <LazyLoadImage
                            width={['HORIZONTAL', 'DOUBLE_PAGING'].indexOf(layout) > -1 ? '50%' : '100%'}
                            key={index}
                            scrollPosition={scrollPosition}
                            src={item} />
                    )}
                    <Box ref={end_box_ref} />
                </Stack>
            </Box>
            <LoginModal disclosure={login_disclosure} />
        </Box>
    )
}

export default trackWindowScroll(ContentReader)