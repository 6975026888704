import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { InitializePayoutAccount } from "../../../services/PaymentService";

interface ConfirmConnectPayoutDialogProps {
    onSave?: (stripe_payout_account: any) => void;
}

export const ConfirmConnectPayoutDialog = ({ onSave }: ConfirmConnectPayoutDialogProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [is_loading, setIsLoading] = useState<boolean>()
    const cancelRef = useRef<any>()

    const save = async ()=> {
        setIsLoading(()=> true)
        const { stripe_payout_account } = await InitializePayoutAccount('US')
        setIsLoading(()=> false)
        onClose()
        if(onSave){
            onSave(stripe_payout_account)
        }
    }

    return (
        <>
            <Button color={'green.400'} onClick={onOpen}>Request Payout</Button>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent bg={'#1F1C2C'}>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Connect a payout account
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            You currently have not connected a payout account. You will receive payments of your content via a Stripe's secure payment method,
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button isLoading={is_loading} loadingText={`Creating account...`} bg={'green.400'} colorScheme={'green'} onClick={save} ml={3}>
                                Proceed
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}