import { createContext, Dispatch, ReactNode, useEffect, useState } from "react";
import { GetCurrent } from "../services/UserService";

interface UserProviderProps {
    children: ReactNode
}

interface ContextProps {
    isReady: boolean;
    setIsReady: Dispatch<React.SetStateAction<boolean>>;
    user: any;
    setUser: Dispatch<React.SetStateAction<any>>;
}

export const UserContext = createContext<ContextProps>({
    isReady: false,
    setIsReady: () => { },
    user: null,
    setUser: () => { },
});

export const UserProvider = ({ children }: UserProviderProps) => {
    const [user, setUser] = useState<any>();
    const [is_ready, setIsReady] = useState<any>();

    useEffect(() => {
        (async () => {
            try {
                const user = await GetCurrent()
                setUser(() => user)
            } catch (e: any) {
                console.log(e.message, 'GetCurrent()')
            }
            setIsReady(()=> true)
        })()
    }, [])

    const value = {
        user, setUser,
        isReady: is_ready, setIsReady
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};