import { RefObject, useEffect, useMemo, useState } from "react"

export const useOnScreen = (ref: RefObject<HTMLElement>) => {

    const [isIntersecting, setIntersecting] = useState<boolean>(false)

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref])


    useEffect(() => {
        observer.observe(ref.current as HTMLElement)
        return () => observer.disconnect()
    }, [])

    return isIntersecting
}