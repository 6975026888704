import { Button, Img, Text, VStack } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { AuthTemplate } from "../../../templates/AuthTemplate"
export const RegisterSuccessPage = () => {
    const navtigate = useNavigate()
    return (
        <AuthTemplate>
            <VStack spacing={'32px'} align={'start'} py={'20px'} px={'24px'} w={'500px'} minH={'300px'} bg={'#262333'} border={'1px solid #2F2C3B'} borderRadius={'8px'}>
                <VStack spacing={'20px'} w={'full'} align={'center'} pt={'24px'}>
                    <Img h={'80px'} src={'/assets/images/icons/checkmark-circle.png'} />
                </VStack>
                <VStack w={'full'} spacing={'4px'}>
                    <Text fontSize={'2xl'}>Successful</Text>
                    <Text color={'gray.400'} fontSize={'sm'} fontWeight={400}>You have successfully created your Novatoons account</Text>
                </VStack>
                <VStack w={'full'} spacing={'16px'}>
                    <Button onClick={()=> navtigate('/')} _hover={{ bg: 'green.300' }} w={'full'} bg={'green.400'} color={'black'}>
                        Proceed
                    </Button>
                </VStack>
            </VStack>
        </AuthTemplate>
    )
}