import { background, VStack } from "@chakra-ui/react"
import { ReactNode } from "react"

interface AuthTemplateProps {
    children: ReactNode
}

const cover_style: any = {
    // backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.94) 0%, rgba(0, 0, 0, 0.94) 100%),url(/assets/images/backgrounds/home-hero.png) no-repeat center center fixed',
    backgroundSize: 'cover',
    backgroundImage: '/assets/images/backgrounds/home-hero.png',
    w: 'full',
    h: '100vh'
}

export const AuthTemplate = ({ children }: AuthTemplateProps) => {
    return (
        <VStack {...cover_style} align={'center'} justify={'center'}>
            { children }
        </VStack>
    )
}