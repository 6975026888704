import { Request, Response } from '@pollyjs/core'
import { GetJson, SaveJson } from './MockHelper'
import { ContentProps, SubContentProps } from '../services/ContentService'
import { filter, findIndex, findWhere, intersection, pluck, where } from 'underscore'
import qs from 'query-string'

const genres = [
    {
        name: 'Action',
        code: 'ACTION'
    },
    {
        name: 'Comedy',
        code: 'COMEDY'
    },
    {
        name: 'Drama',
        code: 'DRAMA'
    },
    {
        name: 'Fantasy',
        code: 'FANTASY'
    },
    {
        name: 'Heartwarming',
        code: 'HEARTWARMING'
    },
    {
        name: 'Historical',
        code: 'HISTORICAL'
    },
    {
        name: 'Horror',
        code: 'HORROR'
    },
    {
        name: 'Informative',
        code: 'INFORMATIVE'
    },
    {
        name: 'Mystery',
        code: 'MYSTERY'
    },
    {
        name: 'Romance',
        code: 'ROMANCE'
    },
    {
        name: 'Sci-Fi',
        code: 'SCI-FI'
    },
    {
        name: 'Slice of life',
        code: 'SLICE_OF_LIFE'
    },
    {
        name: 'Supernatural',
        code: 'SUPERNATURAL'
    },
    {
        name: 'Sports',
        code: 'SPORTS'
    }
]

const tags = [
    {
        name: 'Webtoon',
        code: 'WEBTOON'
    },
    {
        name: 'Manga',
        code: 'MANGA'
    },
    {
        name: 'Anime',
        code: 'ANIME'
    },
    {
        name: 'Webcomic',
        code: 'WEBCOMIC'
    },
    {
        name: 'Digitalart',
        code: 'DIGITALART'
    },
    {
        name: 'Manhwa',
        code: 'MANHWA'
    }
]

export const Save = async (req: Request, res: Response) => {
    try {
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        let [contents, users] = await Promise.all([
            GetJson(`contents`),
            GetJson(`users`)
        ])

        const user = findWhere(users, {
            access_token
        })

        if (user) {
            const data = JSON.parse(req.body as string) as ContentProps
            data.owner = user
            if (data.id) {
                const index = findIndex(contents, { id: data.id })
                if (index > -1) {
                    if (contents[index].status === 'DRAFT') {
                        if (data.submit_for_review) {
                            data.status = 'UNDER_REVIEW'
                        }
                        contents[index] = { ...data }
                    }
                } else {
                    throw new Error(`Can't access content`)
                }
            } else {
                data.id = new Date().getTime()
                contents.push({ ...data, status: 'DRAFT' })
            }
            await SaveJson('contents', contents)
            await SaveReviewContent(data)
            res.status(200).json(data)
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const SaveSubContent = async (req: Request, res: Response) => {
    try {
        let data = JSON.parse(req.body as string) as SubContentProps
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        let [contents, assets, users] = await Promise.all([
            GetJson(`contents`),
            GetJson(`assets`),
            GetJson(`users`)
        ])

        const user = findWhere(users, {
            access_token
        })

        if (user) {
            const index = findIndex(contents, item => item.id === data.parent_id)
            if (data.file_id) {
                const asset = findWhere(assets, {
                    id: data.file_id
                })
                data.file = { ...asset }
            }
            if (index > -1) {
                const content = contents[index]
                if (!content.sub_contents) {
                    content.sub_contents = []
                }
                const sub_content = { ...data, created_at: new Date().getTime() }
                if (data.id) {
                    const _index = findIndex(content.sub_contents, _item => _item.id == data.id)
                    content.sub_contents[_index] = sub_content
                } else {
                    sub_content.id = new Date().getTime()
                    content.sub_contents.push(sub_content)
                }
                if (content.sub_contents.length > 1) {
                    content.type = 'SERIES'
                } else {
                    content.type = 'SINGLE'
                }
                contents[index] = { ...content }
                await SaveJson('contents', contents)
                await SaveReviewContent(content)
                res.status(200).json(sub_content)
            } else {
                throw new Error(`Failed to access parent content`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const DeleteSubContent = async (req: Request, res: Response) => {
    try {
        const { id } = req.params
        const contents = await GetJson(`contents`)
        const _contents = contents.map((item: any) => {
            if (item.sub_contents && item.sub_contents.length > 0) {
                const sub_index = pluck(item.sub_contents, 'id').indexOf(parseInt(id))
                if (sub_index > -1) {
                    item.sub_contents.splice(sub_index, 1)
                }
                return { ...item }
            } else {
                return { ...item }
            }
        })
        await SaveJson(`contents`, _contents)
        res.status(200).json({ message: 'Deleting sub content successful' })
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const Get = async (req: Request, res: Response) => {
    try {
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        let [contents, users, user_contents] = await Promise.all([
            GetJson(`contents`),
            GetJson(`users`),
            GetJson(`user_contents`),
        ])

        const user = findWhere(users, {
            access_token
        })

        const { id } = req.params
        const content = findWhere(contents, { id: parseInt(id) })
        if (content.is_paid) {
            if (user) {
                const sub_content_ids = pluck(where(user_contents, { type: 'PURCHASE', user_id: user.id, content_id: content.id }), 'sub_content_id')
                for (let item of content.sub_contents) {
                    if (sub_content_ids.indexOf(item.id) === -1 && item.pages && item.pages.length > 0) {
                        item.pages = [...item.pages.slice(0, 5)]
                    }
                }
            } else {
                for (let item of content.sub_contents) {
                    if (item.pages && item.pages.length > 0) {
                        item.pages = [...item.pages.slice(0, 5)]
                    }

                }
            }
        }
        res.status(200).json(content)
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const GetDraft = async (req: Request, res: Response) => {
    try {
        const { id } = req.params
        const [contents, review_contents] = await Promise.all([
            GetJson(`contents`),
            GetJson(`review_contents`)
        ])

        let content = findWhere(review_contents, { id: parseInt(id) })
        if (!content) {
            content = findWhere(contents, { id: parseInt(id) })
        }
        if (content) {
            res.status(200).json({ ...content })
        } else {
            throw new Error(`Content not found`)
        }
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const GetListing = async (req: Request, res: Response) => {
    try {
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        if (access_token) {
            const users = await GetJson(`users`)
            const user = findWhere(users, {
                access_token
            })
            if (user) {
                const { password, ..._user } = user
                const [contents, review_contents] = await Promise.all([
                    GetJson(`contents`),
                    GetJson(`review_contents`)
                ])
                const _contents = filter(contents, item => item.owner.id === user.id).map(item => ({ ...item, created_at: new Date(item.id) }))
                const _review_contents = filter(review_contents, item => (item.owner.id === user.id && item.is_final)).map(item => ({ ...item, created_at: new Date(item.id) }))
                const final_contents = [..._contents, ..._review_contents]
                res.status(200).json(final_contents)
            } else {
                throw new Error(`User not authorized`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const GetPublicListing = async (req: Request, res: Response) => {
    try {
        const { categories, genres, tags, audience_types, prices, type, keyword, user_id } = req.query
        let contents = await GetJson(`contents`)
        contents = filter(contents, item => item.status === 'PUBLISHED')
        if (categories) {
            const _categories = typeof (categories) === 'string' ? [categories] : categories
            contents = filter(contents, item => intersection(item.featured_listing, _categories).length > 0)
        }

        if (genres) {
            const _genres = typeof (genres) === 'string' ? [genres] : genres
            contents = filter(contents, item => intersection(item.genres, _genres).length > 0)
        }

        if (tags) {
            const _tags = typeof (tags) === 'string' ? [tags] : tags
            contents = filter(contents, item => intersection(item.tags, _tags).length > 0)
        }

        if (audience_types) {
            const _audience_types = typeof (audience_types) === 'string' ? [audience_types] : audience_types
            contents = filter(contents, item => _audience_types.indexOf(item.audience_type) > -1 || item.audience_type === 'ALL')
        }

        if (prices) {
            let _prices: any[] = typeof (prices) === 'string' ? [prices] : prices
            _prices = _prices.map(item => item === 'FREE' ? false : true)
            contents = filter(contents, item => _prices.indexOf(item.is_paid) > -1)
        }

        if (type && type !== 'ALL') {
            contents = filter(contents, item => item.type === type)
        }

        if (keyword) {
            const _keyword = typeof (keyword) === "string" ? keyword : keyword[0]
            contents = filter(contents, item => item.title.toLowerCase().indexOf(_keyword.toLowerCase()) > -1)
        }

        if(user_id){
            contents = filter(contents, item => item.owner.id === user_id)
        }

        res.status(200).json([...contents.reverse()])
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const SaveReviewContent = async (content: ContentProps) => {
    const [contents, review_contents] = await Promise.all([
        GetJson(`contents`),
        GetJson(`review_contents`)
    ])
    const existing_content = findWhere(contents, { id: content.id })
    if (existing_content.status === 'PUBLISHED') {
        const _index = findIndex(review_contents, { id: content.id })
        if (_index > -1) {
            review_contents[_index] = { ...content, is_final: content.submit_for_review, status: 'UNDER_REVIEW' }
        } else {
            review_contents.push({ ...content, is_final: content.submit_for_review, status: 'UNDER_REVIEW' })
        }
        await SaveJson(`review_contents`, review_contents)
    }
}

export const GetGenreListing = async (req: Request, res: Response) => {
    res.status(200).json([...genres.map(item => ({ ...item, picture_url: `/assets/images/backgrounds/genres/${item.code.toLowerCase()}.png` }))])
}

export const GetTagListing = async (req: Request, res: Response) => {
    res.status(200).json([...tags])
}

export const AddComment = async (req: Request, res: Response) => {
    try {
        const auth_header = req.headers['Authorization'] as string
        let access_token = ''
        if (auth_header) {
            const auth_header_array = auth_header.split(' ')
            if (auth_header_array.length > 1) {
                access_token = auth_header_array[1]
            }
        }

        let [contents, users] = await Promise.all([
            GetJson(`contents`),
            GetJson(`users`)
        ])

        const user = findWhere(users, {
            access_token
        })

        if (user) {
            const {
                content_id,
                sub_content_id,
                message
            } = JSON.parse(req.body as string)

            const content = findWhere(contents, { id: content_id })
            if (content) {
                const sub_content = findWhere(content.sub_contents, { id: sub_content_id })
                if (!sub_content.comments) {
                    sub_content.comments = []
                }

                sub_content.comments.push({
                    id: new Date().getTime(),
                    created_at: new Date(),
                    message,
                    owner: user
                })

                await SaveJson(`contents`, contents)
                res.status(200).json([...sub_content.comments])
            } else {
                throw new Error(`Content not found`)
            }
        } else {
            throw new Error(`User not authorized`)
        }
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}