import { Button, Center, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { useContext, useState } from "react";
import { UserContext } from "../../../providers/UserProvider";

interface RequestPayoutModalProps {
    onSendRequest: (amount: number) => void;
}

export const RequestPayoutModal = ({ onSendRequest }: RequestPayoutModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { user } = useContext(UserContext)
    const [amount, setAmount] = useState<number>(0)
    const [error, setError] = useState<string>()

    const sendRequest = () => {
        if (onSendRequest) {
            onSendRequest(amount)
        }
        onClose()
    }

    const changeAmount = (amount: number) => {
        setAmount(() => amount)
        let balance = 0
        if(user.earnings){
            balance = user.earnings
        }
        if (amount > user.earnings) {
            setError(() => `You can't request to payout an amount more than the total earnings from your wallet`)
        } else {
            setError(() => ``)
        }
    }

    return (
        <>
            <Button color={'green.400'} onClick={onOpen}>Request Payout</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={'#1F1C2C'}>
                    <ModalHeader>Payout</ModalHeader>
                    <ModalBody>
                        <VStack w={'full'} align={'start'} spacing={'16px'}>
                            <VStack w={'full'} spacing={'8px'} align={'start'}>
                                <Text>Amount</Text>
                                <Input variant={'filled'} placeholder={'Enter amount to withdraw'} type={'number'} value={amount} onChange={e => changeAmount(parseFloat(e.target.value))} />
                            </VStack>
                            {error &&
                                <Center borderRadius={'8px'} p={'16px'} bg={'#262333'}>
                                    <Text fontSize={'16px'} color={'orange.400'}>
                                        {error}
                                    </Text>
                                </Center>
                            }
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack w={'full'}>
                            <Button flexGrow={1} color={'green.400'} onClick={sendRequest}>
                                Cancel
                            </Button>
                            <Button isDisabled={error ? true : false} flexGrow={1} bg={'green.400'} colorScheme={'green'} onClick={sendRequest}>
                                Proceed
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}