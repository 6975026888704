import { extendTheme } from '@chakra-ui/react';
import type { StyleFunctionProps } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

import '@fontsource/urbanist/100.css';
import '@fontsource/urbanist/200.css';
import '@fontsource/urbanist/300.css';
import '@fontsource/urbanist/400.css';
import '@fontsource/urbanist/500.css';
import '@fontsource/urbanist/600.css';
import '@fontsource/urbanist/700.css';
import '@fontsource/urbanist/800.css';

const styles = {
    global: (props: StyleFunctionProps) => ({
        body: {
            color: mode('gray.800', '#FFFFFF')(props),
            bg: mode('white', '#1F1C2C')(props),
        },
        shadows: { outline: 'none' }
    }),
};

export const Theme = extendTheme({
    initialColorMode: 'dark',
    useSystemColorMode: false,
    styles,
    fonts: {
        heading: `'Urbanist', sans-serif`,
        body: `'Urbanist', sans-serif`,
    },
})