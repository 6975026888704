import { Button, HStack, Img, Input, Link, Text, useToast, VStack } from "@chakra-ui/react"
import { AuthTemplate } from "../../../templates/AuthTemplate"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { GetUnverified } from "../../../services/UserService"
import { Verify } from "../../../services/AuthService"
import ReactCodeInput from "react-code-input"
import { Storage } from '@ionic/storage'

const store = new Storage({
    name: process.env.REACT_APP_STORAGE_NAME
})

interface EmailVerificationPageProps{
    source?: 'RECOVER_ACCOUNT' | 'VERIFY_ACCOUNT'
}

const code_input_style: any = {
    inputStyle: {
        backgroundColor: '#262333',
        border: '4px solid #535263',
        width: '60px',
        height: '60px',
        borderRadius: '8px',
        margin: '4px',
        fontSize: '30px',
        textAlign: 'center',
        fontWeight: 600,
        outline: 'none'
    }
}

export const EmailVerificationPage = ({ source = 'VERIFY_ACCOUNT' }: EmailVerificationPageProps) => {
    const navigate = useNavigate()
    const toast = useToast()
    const { token } = useParams()
    const [user, setUser] = useState<any>()
    const [code, setCode] = useState<string>('')
    const [is_verifying, setIsVerifying] = useState<boolean>()

    useEffect(() => {
        if (token) {
            (async () => {
                const user = await GetUnverified(token)
                setUser(() => user)
            })()
        }
    }, [token])

    const verify = async () => {
        const storage = await store.create();
        setIsVerifying(() => true)
        try {
            const { access_token } = await Verify(token as string, code)

            if(source==='VERIFY_ACCOUNT'){
                navigate('/register/success')
            }

            if(source==='RECOVER_ACCOUNT'){
                await storage.set(`access_token`, access_token)
                navigate('/account/recover')
            }
            
        } catch (e: any) {
            toast({
                status: `error`,
                description: e.message,
                duration: 3000,
                position: 'bottom-right'
            })
        }
        setIsVerifying(() => false)
    }

    return (
        <AuthTemplate>
            <VStack spacing={'32px'} align={'start'} py={'20px'} px={'24px'} w={'500px'} minH={'300px'} bg={'#262333'} border={'1px solid #2F2C3B'} borderRadius={'8px'}>
                <VStack spacing={'20px'} w={'full'} align={'start'}>
                    <Img h={'50px'} src={'/assets/images/logo-vertical.png'} />
                    <VStack align={'start'} w={'full'} spacing={'4px'}>
                        <Text fontWeight={600} fontSize={'2xl'}>Email Verification</Text>
                        <Text fontSize={'sm'} fontWeight={400} color={'gray.400'}>Enter the OTP verification code that was sent to {user?.email}</Text>
                    </VStack>
                </VStack>
                <VStack w={'full'} spacing={'16px'}>
                    <ReactCodeInput value={code} onChange={code => setCode(code)} type='number' fields={4} {...code_input_style} />
                </VStack>
                <VStack w={'full'} spacing={'16px'}>
                    <Button isDisabled={code.length<4} isLoading={is_verifying} loadingText={`Verifying Account...`} onClick={verify} _hover={{ bg: 'green.300' }} w={'full'} bg={'green.400'} color={'black'}>
                        Proceed
                    </Button>
                    <HStack align={'center'} justify={'center'} w={'full'}>
                        <Text fontSize={'sm'}>Didn't get the code?</Text>
                        <Link fontWeight={600}>Resend</Link>
                    </HStack>
                </VStack>
            </VStack>
        </AuthTemplate>
    )
}