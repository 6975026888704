import { ColorModeScript } from "@chakra-ui/react"
import { init } from "@emailjs/browser"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { App } from "./App"
import { MockServer } from "./mocks/MockServer"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { Worker } from "@react-pdf-viewer/core"
import './global.css'
import { GoogleOAuthProvider } from "@react-oauth/google"


//  initialize mock server
MockServer()

//  initialize emailjs
init(process.env.REACT_APP_EMAILJS_KEY as string)

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <GoogleOAuthProvider clientId="917674942665-69eh6msf4vv8sjdir86crum9kc8s7d3f.apps.googleusercontent.com">
      <BrowserRouter>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <App />
        </Worker>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

