import { send } from '@emailjs/browser'
import { Request, Response } from '@pollyjs/core'
import { findIndex, findWhere } from 'underscore'
import { RegisterReqProps } from '../services/AuthService'
import { GenerateToken, GetFacebookUser, GetGoogleUser, GetJson, SaveJson, SendEmail } from './MockHelper'
import { polly } from './MockServer'

export const Register = async (req: Request, res: Response) => {
    await new Promise(r => setTimeout(r, 1000))
    try {
        const data = JSON.parse(req.body as string) as RegisterReqProps
        const users = await GetJson(`users`)
        const index = findIndex(users, item => item.email === data.email)
        const verification_code = Math.floor(1000 + Math.random() * 9000)
        const verification_token = GenerateToken(48)
        if (index > -1) {
            const user = users[index]
            if (!user.is_verified) {
                users[index].verification_code = verification_code
                users[index].verification_token = verification_token
            } else {
                throw new Error(`Email is already associated with another account`)
            }
        } else {
            users.push({
                id: new Date().getTime(),
                ...data,
                verification_code,
                verification_token,
                is_verified: false
            })
        }
        await SaveJson(`users`, users)
        await SendEmail(data, verification_code.toString())
        // await send(process.env.REACT_APP_EMAILJS_SERVICE_ID as string, process.env.REACT_APP_EMAILJS_REGISTER_TEMPLATE_ID as string, {
        //     name: `${data.firstname} ${data.lastname}`,
        //     code: verification_code,
        //     recipient: data.email
        // })
        res.status(200).json({ message: `We just sent the email verification code to your email`, token: verification_token })
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const Verify = async (req: Request, res: Response) => {
    await new Promise(r => setTimeout(r, 1000))
    try {
        const { code, token } = JSON.parse(req.body as string)
        const users = await GetJson(`users`)
        const index = findIndex(users, item => item.verification_code === parseInt(code) && item.verification_token === token)
        if (index > -1) {
            const access_token = GenerateToken(200)
            users[index].is_verified = true
            users[index].access_token = access_token
            delete users[index].verification_code
            delete users[index].verification_token
            await SaveJson(`users`, users)
            res.status(200).json({ message: `Account successfully verified`, access_token })
        } else {
            throw new Error(`Verification code didn't match, please try again`)
        }
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const Login = async (req: Request, res: Response) => {
    await new Promise(r => setTimeout(r, 1000))
    try {
        const { email, password } = JSON.parse(req.body as string)
        const users = await GetJson(`users`)
        const index = findIndex(users, item => item.email === email && item.password === password)
        if (index > -1) {
            const verification_token = GenerateToken(48)
            const verification_code = Math.floor(1000 + Math.random() * 9000)
            const access_token = GenerateToken(200)
            users[index].access_token = access_token
            const { password, ...user } = users[index]
            if (!user.is_verified) {
                users[index].verification_code = verification_code
                users[index].verification_token = verification_token
                await SendEmail(user, verification_code.toString())
                // await send(process.env.REACT_APP_EMAILJS_SERVICE_ID as string, process.env.REACT_APP_EMAILJS_REGISTER_TEMPLATE_ID as string, {
                //     name: `${user.firstname} ${user.lastname}`,
                //     code: verification_code,
                //     recipient: user.email
                // })
            }
            await SaveJson(`users`, users)
            res.status(200).json({
                ...user,
                verification_token
            })
        } else {
            res.status(401).json({ message: `Email and password didn't match` })
        }
    } catch (e: any) {
        res.status(500).json({ message: e.message })
    }
}

export const LoginWithFacebook = async (req: Request, res: Response) => {
    await new Promise(r => setTimeout(r, 1000))
    try {
        const user_access_token = GenerateToken(200)
        const users = await GetJson(`users`)
        await polly.pause()
        const { access_token } = JSON.parse(req.body as string)
        const { email, firstname, lastname } = await GetFacebookUser(access_token) as any
        const index = findIndex(users, item => item.email === email)

        let response_user;
        if (index > -1) {
            users[index].access_token = user_access_token
            users[index].is_verified = true
            delete users[index].verification_code
            delete users[index].verification_token
            response_user = {
                ...users[index]
            }
        } else {
            response_user = {
                id: new Date().getTime(),
                access_token: user_access_token,
                email,
                firstname,
                lastname,
                is_verified: true,
                password: ''
            }
            users.push(response_user)
        }
        await SaveJson(`users`, users)
        await polly.play()
        res.status(200).json({ access_token: user_access_token })
    } catch (e: any) {
        res.status(500).json({ message: 'Facebook login failed' })
    }
}

export const LoginWithGoogle = async (req: Request, res: Response)=> {
    await new Promise(r => setTimeout(r, 1000))
    try {
        const user_access_token = GenerateToken(200)
        const users = await GetJson(`users`)
        const { access_token } = JSON.parse(req.body as string)

        const { email, firstname, lastname } = await GetGoogleUser(access_token) as any
        const index = findIndex(users, item => item.email === email)

        let response_user;
        if (index > -1) {
            users[index].access_token = user_access_token
            users[index].is_verified = true
            delete users[index].verification_code
            delete users[index].verification_token
            response_user = {
                ...users[index]
            }
        } else {
            response_user = {
                id: new Date().getTime(),
                access_token: user_access_token,
                email,
                firstname,
                lastname,
                is_verified: true,
                password: ''
            }
            users.push(response_user)
        }
        await SaveJson(`users`, users)
        res.status(200).json({ access_token: user_access_token })
    } catch (e: any) {
        res.status(500).json({ message: 'Facebook login failed' })
    }
}