import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure } from "@chakra-ui/react"
import { useRef } from "react"
import { useNavigate } from "react-router-dom"

interface ContentNotAccessibleDialogProps {

}

export const ContentNotAccessibleDialog = ({ }: ContentNotAccessibleDialogProps) => {
    const cancelRef = useRef<any>()
    const navigate = useNavigate()

    const onClose = ()=> {
        navigate(-1)
    }

    return (
        <AlertDialog
            isOpen={true}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent bg={'#1F1C2C'}>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Content Unavailable
                    </AlertDialogHeader>

                    <AlertDialogBody >
                        This content is either under review or not yet ready for reading. Please check again later.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button colorScheme={'green'} bg={'green.400'} onClick={onClose}>
                            Proceed
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}