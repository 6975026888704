import { Storage } from '@ionic/storage'

export enum AssetType {
    CONTENT_COVER = 'content-covers',
    CONTENT = 'contents',
    PROFILE_PICTURE = 'profile-pictures',
    PROFILE_COVER = 'profile-cover',
}

const store = new Storage({
    name: process.env.REACT_APP_STORAGE_NAME
})

export interface AssetReqProps {
    asset_type: AssetType;
    filename: string;
    url: string;
    size: number;
}

export const Save = async (data: AssetReqProps) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/assets/save`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(data),
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const GenerateUploadUrl = async (asset_type: AssetType | string, filename: string, file: File) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/assets/url/generate`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
            asset_type,
            filename,
            file
        }),
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}