import { Box, HStack, IconButton, Image, StackProps, Text, VStack } from "@chakra-ui/react"
import { FiX } from "react-icons/fi"

interface ReadContentSettingsProps extends StackProps {
    onClose?: () => void;
    onChangeView?: (view: 'HORIZONTAL' | 'VERTICAL' | 'SINGLE_PAGING' | 'DOUBLE_PAGING' | string) => void;
}

export const ReadContentSettings = ({ onClose, onChangeView }: ReadContentSettingsProps) => {

    const changeView = (view: string) => {
        if (onChangeView) {
            onChangeView(view)
        }
    }

    return (
        <VStack align={'start'} borderRadius={'8px'} w={'440px'} bg={'#262333'}>
            <HStack p={'24px'} w={'full'} justify={'space-between'}>
                <Text fontSize={'24px'} fontWeight={600}>Settings</Text>
                <IconButton onClick={onClose} aria-label={'Info'} icon={<FiX />} />
            </HStack>
            <Box pb={'12px'} px={'24px'}>
                <Text color={'gray.300'} fontSize={'14px'}>Page Layout</Text>
            </Box>
            <HStack pb={'24px'} pl={'24px'} spacing={'8px'} w={'full'}>
                <VStack onClick={() => changeView('VERTICAL')} cursor={'pointer'} align={'start'}>
                    <Image w={'92px'} src={'/assets/images/contents/scroll-vertical.png'} />
                    <Text color={'gray.200'} fontSize={'12px'}>Vertical scroll</Text>
                </VStack>
                <VStack onClick={() => changeView('HORIZONTAL')} cursor={'pointer'} align={'start'}>
                    <Image w={'92px'} src={'/assets/images/contents/scroll-horizontal.png'} />
                    <Text color={'gray.200'} fontSize={'12px'}>Horizontal scroll</Text>
                </VStack>
                <VStack onClick={() => changeView('SINGLE_PAGING')} cursor={'pointer'} align={'start'}>
                    <Image w={'92px'} src={'/assets/images/contents/scroll-single-page.png'} />
                    <Text color={'gray.200'} fontSize={'12px'}>Single page scroll</Text>
                </VStack>
                <VStack onClick={() => changeView('DOUBLE_PAGING')} cursor={'pointer'} align={'start'}>
                    <Image w={'92px'} src={'/assets/images/contents/scroll-double-page.png'} />
                    <Text color={'gray.200'} fontSize={'12px'}>Double page scroll</Text>
                </VStack>
            </HStack>
        </VStack>
    )
}