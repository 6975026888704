import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogHeader, AlertDialogOverlay, Box, Button, Img, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { ReactNode, useRef } from "react"
import { useScreenSize } from "../../../providers/ScreenSizeProvider";

interface PayoutRedirectDialogProps {
    children: ReactNode
    onFinish?: () => void;
    onStart?: ()=> void;
}

export const PayoutRedirectDialog = ({ children, onStart, onFinish }: PayoutRedirectDialogProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef<any>()
    const { isMobile } = useScreenSize()

    const open = async ()=> {
        onOpen()
        if(onStart){
            await onStart()
        }
        
        await new Promise(r => setTimeout(r, 1000));

        if(onFinish){
            onFinish()
        }
        onClose()
    }


    return (
        <>
            <Box cursor={'pointer'} onClick={open}>
                {children}
            </Box>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                size={isMobile ? 'sm' : 'xl'} 
            >
                <AlertDialogOverlay />

                <AlertDialogContent bg={'#1F1C2C'}>
                    <AlertDialogBody py={'32px'}>
                        <VStack w={'full'} px={'35px'} py={'24px'} spacing={'32px'}>
                            <VStack w={'full'} spacing={'12px'}>
                                <Text fontSize={'2xl'}>Payout redirect...</Text>
                                <Text textAlign={'center'} color={'gray.400'} fontSize={'sm'} fontWeight={400}>
                                    You'll be redirected to Stripe to connect your payout account. Once completed, you'll return to Novatoons.
                                </Text>
                            </VStack>
                        </VStack>
                    </AlertDialogBody>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )
}